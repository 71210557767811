import { Button, Col, Form, Input, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { useHistory } from "react-router-dom";
// import axios from "axios";
import urlLoading from "../assets/images/loading.gif";
import styles from "./styles.module.css";
import { useWeb3ExecuteFunction } from "react-moralis";
import { useMoralisDapp } from "providers/MoralisDappProvider/MoralisDappProvider";

function NFTCreate(props) {
  const contractProcessor = useWeb3ExecuteFunction();
  const { marketAddress, contractABI } = useMoralisDapp();
  const contractABIJson = JSON.parse(contractABI);
  const createToken = "createToken";
  const { Moralis, account, isAuthenticated } = useMoralis();
  const serverURL = process.env.REACT_APP_MORALIS_SERVER_URL;
  const appId = process.env.REACT_APP_MORALIS_APPLICATION_ID;
  Moralis.initialize(appId);
  Moralis.serverURL = serverURL;
  const [form] = Form.useForm();
  const history = useHistory();

  const [formInput, updateFormInput] = useState({ name: "", description: "" });
  const [fileType, setFileType] = useState();
  const [fileName, setFileName] = useState("");
  const [visible, setVisible] = useState(false);
  const [formValid, setFormValid] = useState({
    nameErr: false,
    descriptionErr: false,
    fileErr: false,
  });
  const [nameValid, setNameValid] = useState(false);
  const [descValid, setDescValid] = useState(false);
  const [isValidType, setIsValidType] = useState(true);
  const [mediaSrc, setMediaSrc] = useState();
  const [isValidFileName, setValidFileName] = useState(true);

  const checkAuthen = async () => {
    if (account && isAuthenticated) {
      const users = Moralis.Object.extend("profile");
      const query = new Moralis.Query(users);
      query.equalTo("address", account.toLowerCase());
      const data = await query.first();

      if (data) {
        // setUser(data.attributes);
      } else {
        history.push("/profile");
      }
    }
  };

  useEffect(() => {
    checkAuthen();
  }, [account, isAuthenticated]);

  function checkValidType(file) {
    let result = false;
    // .JPG, .PNG, .MP4, .MP3, .WAV.
    let fileExtension = file.name.slice(-4).toLowerCase();
    let filesize = file.size;

    if (
      (fileExtension === ".jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === ".png" ||
        fileExtension === ".mp4" ||
        fileExtension === ".mp3" ||
        fileExtension === ".wav") &&
      filesize <= 52428800
    ) {
      result = true;
    }
    return result;
  }

  function checkValidName(name) {
    let extensionLength = 4;
    let fileExtension = name.slice(-5).toLowerCase();
    if (fileExtension === ".jpeg") {
      extensionLength = 5;
    }

    let _name = name.substring(0, name.length - extensionLength);
    // eslint-disable-next-line
    let format = /[^A-Z a-z0-9_-]/;
    return format.test(_name) ? false : true;
  }

  const onChangeImage = async (e) => {
    let file = e.target.files[0];
    if (file === undefined) return;
    console.log("File Info", file);
    setIsValidType(true);
    setFileName("");
    setMediaSrc("");
    setFileType("");
    setValidFileName(true);

    if (!checkValidType(file)) {
      setIsValidType(false);
      return;
    }

    if (!checkValidName(file.name)) {
      setValidFileName(false);
      setFileName(file.name);
      return;
    }

    setFileName(file.name);
    setFileType(file.type);
    await uploadImageData(e);
    setVisible(false);
  };

  const uploadImageData = async (e) => {
    const data = e.target.files[0];
    console.log("File Data", data);
    const file = new Moralis.File(data.name, data);
    console.log("File", file);
    await file.saveIPFS();
    let fileUrl = file.ipfs();
    console.log("File URL", fileUrl);
    setMediaSrc(fileUrl);
    return fileUrl;
  };

  const uploadMetaData = async (imgUrl) => {
    console.group("UploadMetaData");
    const metadata = {
      name: formInput.name,
      description: formInput.description,
      image: imgUrl,
    };
    console.log("metadata", metadata);

    const file = new Moralis.File("file.json", {
      base64: btoa(JSON.stringify(metadata)),
    });
    await file.saveIPFS();
    let fileUrl = file.ipfs();
    console.log("fileUrl", fileUrl);
    console.groupEnd();
    return file.ipfs();
  };

  function isFormValid() {
    if (
      formInput.name === "" &&
      formInput.description === "" &&
      fileName === ""
    ) {
      setFormValid({
        ...formValid,
        nameErr: true,
        descriptionErr: true,
        fileErr: true,
      });
      return false;
    }

    if (formInput.name === "" && formInput.description === "") {
      setFormValid({ ...formValid, nameErr: true, descriptionErr: true });
      return false;
    }

    if (formInput.name === "" && fileName === "") {
      setFormValid({ ...formValid, nameErr: true, fileErr: true });
      return false;
    }

    if (formInput.description === "" && fileName === "") {
      setFormValid({ ...formValid, descriptionErr: true, fileErr: true });
      return false;
    }

    if (formInput.name === "") {
      setFormValid({ ...formValid, nameErr: true });
      return false;
    }

    if (formInput.description === "") {
      setFormValid({ ...formValid, descriptionErr: true });
      return false;
    }

    if (fileName === "") {
      setFormValid({ ...formValid, fileErr: true });
      return false;
    }

    if (
      formInput.name !== "" &&
      formInput.description !== "" &&
      fileName !== ""
    ) {
      setFormValid({
        ...formValid,
        nameErr: false,
        descriptionErr: false,
        fileErr: false,
      });
      return true;
    }
  }

  function checkValidInput(input) {
    if (input === "") return true;
    let format = /[^A-Z a-z0-9!@#$%^&*().,_+<>/?:"'|\\[={}`~\]\-\n]/;
    return format.test(input) ? false : true;
  }

  const handleInputName = (name) => {
    updateFormInput({ ...formInput, name: name });
    if (checkValidInput(name)) {
      setNameValid(true);
      return;
    }
    setNameValid(false);
  };

  const handleInputDesc = (description) => {
    updateFormInput({ ...formInput, description: description });
    if (checkValidInput(description)) {
      setDescValid(true);
      return;
    }
    setDescValid(false);
  };

  async function createNFT(metadata) {
    if (isFormValid()) {
      if (!nameValid || !descValid) return;
      setVisible(true);
      const ops = {
        contractAddress: marketAddress,
        functionName: createToken,
        abi: contractABIJson,
        params: {
          tokenURI: metadata,
        },
      };
      await contractProcessor.fetch({
        params: ops,
        onSuccess: () => {
          setTimeout(() => {
            setVisible(false);
            successCreate();
          }, 20000);
        },
        onError: (error) => {
          updateFormInput({ ...formInput, name: "", description: "" });
          setFileType("");
          setMediaSrc("");
          setVisible(false);
          failCreate();
        },
      });
    }
  }
  async function handleCreateClicked() {
    if (!nameValid) {
      setFormValid({ ...formValid, nameErr: true });
      return;
    }
    if (!descValid) {
      setFormValid({ ...formValid, descriptionErr: true });
      return;
    }
    if (mediaSrc === "") {
      setFormValid({ ...formValid, fileErr: true });
      return;
    }
    const metadata = await uploadMetaData(mediaSrc);
    await createNFT(metadata);
  }

  function successCreate() {
    let secondsToGo = 5;
    const modal = Modal.success({
      title: "Success!",
      content: `NFT is created, you may check your NFT`,
    });
    history.push("/my-collection");
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  function failCreate() {
    let secondsToGo = 5;
    const modal = Modal.error({
      title: "Error!",
      content: `There was a problem with creating NFT`,
    });
    setFileName("");
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  return (
    <div style={{ width: "100%", alignItems: "center" }}>
      <div className={styles.createForm}>
        <div className={styles.formBore}>
          <Form form={form} layout="vertical">
            <Row gutter={32}>
              <Col span={24}>
                <Form.Item>
                  <label>Asset Name</label>
                  <Input
                    value={formInput.name}
                    placeholder="Asset name"
                    onChange={(e) => handleInputName(e.target.value)}
                  />
                  <div style={{ color: "red" }}>
                    {!formInput.name && formValid.nameErr
                      ? "Please input your asset name"
                      : formInput.name && !nameValid
                      ? "English only"
                      : ""}
                  </div>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <label>Description</label>
                  <Input.TextArea
                    placeholder="Asset Description"
                    rows={5}
                    value={formInput.description}
                    onChange={(e) => handleInputDesc(e.target.value)}
                    // style={{'white-space': 'pre-wrap'}}
                  />
                  <div style={{ color: "red" }}>
                    {!formInput.description && formValid.descriptionErr
                      ? "Please input your description"
                      : formInput.description && !descValid
                      ? "English only"
                      : ""}
                  </div>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <label>Image</label>
                  <Input
                    allowClear
                    type="file"
                    onChange={onChangeImage}
                    accept=".jpg,.jpeg,.mp4,.mp3,.png,.wav"
                  />
                  {
                    !mediaSrc && fileType && isValidType ? (
                      <img
                        alt=""
                        src={urlLoading}
                        style={{ margin: "10px 0 10px 0" }}
                        width="45"
                      />
                    ) : mediaSrc && fileType?.includes("video") ? (
                      <video
                        width="350"
                        controls
                        style={{ margin: "10px 0 10px 0" }}
                      >
                        {" "}
                        <source src={mediaSrc} type={fileType}></source>
                      </video>
                    ) : mediaSrc && fileType?.includes("audio") ? (
                      <audio
                        width="350"
                        controls
                        style={{ margin: "10px 0 10px 0" }}
                      >
                        {" "}
                        <source src={mediaSrc} type={fileType}></source>
                      </audio>
                    ) : mediaSrc && fileType?.includes("image") ? (
                      <img
                        alt=""
                        src={mediaSrc}
                        style={{
                          margin: "10px 0 10px 0",
                          width: "210px",
                          height: "210px",
                        }}
                        type={fileType}
                        width="350"
                      />
                    ) : (
                      ""
                    )

                    //          <audio className="rounded mt-4" style={{ margin: '10px 0 10px 0' }} width="350" controls>
                    //          <source src={url} type={type}></source>
                    //          </audio>
                  }
                  <div style={{ color: "red" }}>
                    {!fileName && formValid.fileErr
                      ? "Please upload your NFT file"
                      : ""}
                  </div>
                  <div style={{ color: "red" }}>
                    {!isValidFileName
                      ? "Please remove the special character in the filename"
                      : ""}
                  </div>
                  <div
                    style={
                      isValidType
                        ? { color: "black", fontSize: "12px" }
                        : { color: "red", fontSize: "12px" }
                    }
                  >
                    File types supported: JPG, JPEG, PNG, MP4, MP3, WAV.
                  </div>
                  <div
                    style={
                      isValidType
                        ? { color: "black", fontSize: "12px" }
                        : { color: "red", fontSize: "12px" }
                    }
                  >
                    Max file size : 50MB
                  </div>
                </Form.Item>
              </Col>
              <Col
                span={24}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  onClick={() => handleCreateClicked()}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className={styles.btnCreate}
                  loading={visible ? true : false}
                  disabled={!mediaSrc && fileType && isValidType ? true : false}

                  // style={{ width: "auto", borderRadius: "12px" }}
                >
                  {visible ? "Creating" : "Create NFT"}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default NFTCreate;

import Constants from "constant";

export const networkCollections = {
  "0x38": [
    // {
    //   image:
    //     "https://ipfs.moralis.io:2053/ipfs/QmQTsPzaH6yoDW6SiRhvUYJ46dYSbmzaDCfSkyJQ4t9ym6",
    //   banner:
    //     "https://ipfs.moralis.io:2053/ipfs/QmZufDkpWCU2Dcq7CHEhGeDBgtgppcLEzBfB4WuQvM8TUM",
    //   name: "Metamints Free Collection",
    //   addrs: "0x68F33d25b2Ba9d60Cc6615d29d30fF069F840911",
    //   added: true,
    //   type: Constants.collections.types.FREE,
    //   createdBy: "Metamints Free Team",
    //   description:
    //     "AI Robotics is not just a normal NFT but a personal experience that you will live with this beautiful personality, grow and improve by the day. With several features packed in the Robotics NFT, it brings the next level of AI-infused companions to life seamlessly.",
    //   statistics: {
    //     totalItems: "9,999",
    //     totalOwners: "1",
    //     floorPrice: "0",
    //     volumeTrade: "0",
    //   },
    // },
    {
      image:
        "https://ipfs.moralis.io:2053/ipfs/QmZi4FRfT5qU9gWTxbN3YhVe9axeTZJbsVE1JMsSMT3dPD",
      banner:
        "https://ipfs.moralis.io:2053/ipfs/Qmcw2dNfuqsJueoohmKvQ8utizg5VJCeknfhEvshQmgRuE",
      name: "Metamints AI Robots",
      addrs: "0x68F33d25b2Ba9F069F811",
      added: true,
      type: Constants.collections.types.CHARGE,
      createdBy: "Metamints AI Robots Team",
      description:
        "AI Robotics is not just a normal NFT but a personal experience that you will live with this beautiful personality, grow and improve by the day. With several features packed in the Robotics NFT, it brings the next level of AI-infused companions to life seamlessly.",
      statistics: {
        totalItems: "9,999",
        totalOwners: "1",
        floorPrice: "300",
        volumeTrade: "0",
      },
    },
    {
      image:
        "https://bafkreibjvl6d7vxoigzcc4bqbgtzsolzhihqc3sklfrngq7xidbu6z6esa.ipfs.nftstorage.link/",
      banner:
        "https://bafybeiglqygf2hj4ccfykxfgmn2ttky76n47yxm4kqumngd35cfuzs637a.ipfs.nftstorage.link/",
      name: "1988 Dragon",
      addrs: "0x230f55e5d30dfc1bd9de65d9b644820553e72486",
      added: true,
      type: Constants.collections.types.CHARGE,
      createdBy: "1988 Dragon Team",
      description:
        "1988Dragon's purpose is to create a direct, reliable opportunity for celebrities and influencers and mutually beneficial to a broad group of audiences.",
      statistics: {
        totalItems: "1,988",
        totalOwners: "254",
        floorPrice: "0.4",
        volumeTrade: "124k",
      },
    },
  ],
  "0x89": [
    {
      image:
        "https://lh3.googleusercontent.com/4atnnFqMOyNdFlQwKuxD9ijUeIOzcJCwIrPCIsUnnzJFaOlEfkrQFHWIVDUS4L3GmLNyIbq3STFZOOVZ64kTpSy3iGM2PJAa3CZWu48=s0",
      name: "DokiDoki",
      addrs: "0xa699ef0fa979b3d4e2107e6a5b4147ce92d9cb02",
    },
    {
      image:
        "https://lh3.googleusercontent.com/eW5nOj3vY1lnW1SzVEatHKgmwB82YdFsrzyYp3eW-x371EpIuLMjOqd5VezBKSZya3z3NZNbFr8Tlk2NAGoPc6hiaRbn9jFN2tgJQA=s0",
      name: "Polygonpunks",
      addrs: "0x9498274b8c82b4a3127d67839f2127f2ae9753f4",
    },
    {
      image:
        "https://lh3.googleusercontent.com/8Dzq7wLKOMUcCd0v2xsakhEXVQwlgtU69c5tChkp2fljgtwCO5QkOwkCgErmwU9caCd78doWwmdcnj2hOW_Qh41RmgMsgCJgJrvvdTg=s0",
      name: "Crypto Raiders",
      addrs: "0xcd1af180a10ad5924a6474d310045d38a229555d",
    },
  ],
  "0x61": [
    {
      image:
        "https://lh3.googleusercontent.com/eW5nOj3vY1lnW1SzVEatHKgmwB82YdFsrzyYp3eW-x371EpIuLMjOqd5VezBKSZya3z3NZNbFr8Tlk2NAGoPc6hiaRbn9jFN2tgJQA=s0",
      banner:
        "https://cdn.sanity.io/images/kt6t0x48/production/41e51630c43b9ade112281066bb22327dbc16fcd-2000x2000.jpg",
      name: "Metamints ",
      addrs: "0xAAc6CE62CD4253c1b5A2902b5A1b1D9464256A54",
      added: true,
    },
  ],
  "0x13881": [
    //Add Your Collections here
    {
      image:
        "https://lh3.googleusercontent.com/BWCni9INm--eqCK800BbRkL10zGyflxfPwTHt4XphMSWG3XZvPx1JyGdfU9vSor8K046DJg-Q8Y4ioUlWHiCZqgR_L00w4vcbA-w=s0",
      name: "Test Mages",
      addrs: "0x4f914EE31cb44D04c3CE8FCCd6573d9aF36C8780",
    },
    {
      image:
        "https://ipfs.moralis.io:2053/ipfs/QmfLbpeVHxReWKNLaXTPcWiafi49eoAL4gRwMGuXtx2Eqe/images/14.png",
      name: "Pixel Show",
      addrs: "0xCA34404dD8Bd6537BE76F3A51B379F8949bD7973",
    },
  ],

  "0x1": [
    {
      image:
        "https://lh3.googleusercontent.com/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB=s130",
      banner:
        "https://lh3.googleusercontent.com/i5dYZRkVCUK97bfprQ3WXyrT9BnLSZtVKGJlKQ919uaUB0sxbngVCioaiyu9r6snqfi2aaTyIvv6DHm4m2R3y7hMajbsv14pSZK8mhs=h600",
      name: "Bored Ape Yacht Club",
      addrs: "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D",
    },
    {
      image:
        "https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s130",
      banner:
        " https://lh3.googleusercontent.com/48oVuDyfe_xhs24BC2TTVcaYCX7rrU5mpuQLyTgRDbKHj2PtzKZsQ5qC3xTH4ar34wwAXxEKH8uUDPAGffbg7boeGYqX6op5vBDcbA=h600",
      name: "Crypto Punks",
      addrs: "0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB",
    },
    {
      image:
        "https://lh3.googleusercontent.com/7B0qai02OdHA8P_EOVK672qUliyjQdQDGNrACxs7WnTgZAkJa_wWURnIFKeOh5VTf8cfTqW3wQpozGedaC9mteKphEOtztls02RlWQ=s0",
      banner:
        "https://lh3.googleusercontent.com/svc_rQkHVGf3aMI14v3pN-ZTI7uDRwN-QayvixX-nHSMZBgb1L1LReSg1-rXj4gNLJgAB0-yD8ERoT-Q2Gu4cy5AuSg-RdHF9bOxFDw=h600",
      name: "Doodles",
      addrs: "0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e",
    },
    {
      image:
        "https://lh3.googleusercontent.com/7gOej3SUvqALR-qkqL_ApAt97SpUKQOZQe88p8jPjeiDDcqITesbAdsLcWlsIg8oh7SRrTpUPfPlm12lb4xDahgP2h32pQQYCsuOM_s=s0",
      banner:
        "https://lh3.googleusercontent.com/a5IYMKaL19_3PhnVHM1f4pavIGLos1VEIw61d5yDPJprXdvmnTmc-EZMibYW8mOo4d8mP02KQ0SK_2RFUR0Hbajd6E7v-unyrD0vxw=h600",
      name: "0N1 Force",
      addrs: "0x3bf2922f4520a8BA0c2eFC3D2a1539678DaD5e9D",
    },
    {
      image:
        "https://lh3.googleusercontent.com/lHexKRMpw-aoSyB1WdFBff5yfANLReFxHzt1DOj_sg7mS14yARpuvYcUtsyyx-Nkpk6WTcUPFoG53VnLJezYi8hAs0OxNZwlw6Y-dmI=s0",
      banner:
        "https://lh3.googleusercontent.com/5c-HcdLMinTg3LvEwXYZYC-u5nN22Pn5ivTPYA4pVEsWJHU1rCobhUlHSFjZgCHPGSmcGMQGCrDCQU8BfSfygmL7Uol9MRQZt6-gqA=h600",
      name: "Mutant Ape Yacht Club",
      addrs: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
    },
    {
      image:
        "https://lh3.googleusercontent.com/LIpf9z6Ux8uxn69auBME9FCTXpXqSYFo8ZLO1GaM8T7S3hiKScHaClXe0ZdhTv5br6FE2g5i-J5SobhKFsYfe6CIMCv-UfnrlYFWOM4=s0",
      banner:
        "https://lh3.googleusercontent.com/8QAOgrzgfu8tR0K80iZW_93o9dUpyGMACpwr4kSeTvinu2hYBvzMOyLoHspo6IPpz7G2Xe57VTXfgGPECzWTaH_oYFNVCuA12AIDJA=h600",
      name: "CyberKongz",
      addrs: "0x57a204AA1042f6E66DD7730813f4024114d74f37",
    },
    {
      image:
        "https://lh3.googleusercontent.com/LIov33kogXOK4XZd2ESj29sqm_Hww5JSdO7AFn5wjt8xgnJJ0UpNV9yITqxra3s_LMEW1AnnrgOVB_hDpjJRA1uF4skI5Sdi_9rULi8=s0",
      banner:
        "https://lh3.googleusercontent.com/H4Iu36XQNJqVlF99-0BuQna0sUlUcIrHt97ss3le_tAWw8DveEBfTktX3S0bP6jpC9FhN1CKZjoYzZFXpWjr1xZfQIwSSLeDjdi0jw=h600",
      name: "Cool Cats NFT",
      addrs: "0x1A92f7381B9F03921564a437210bB9396471050C",
    },
  ],
};
export const getCollectionsByChain = (chain) => networkCollections[chain];

export const getCollectionByAddress = (address, chainId) => {
  const collections = networkCollections[chainId];
  return collections
    ? collections.find((collection) => collection.addrs === address)
    : {};
};

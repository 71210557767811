import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";

const Popup = () => {
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState(
    "We have just upgraded the system for a comprehensive upgrade of system commissions. If the new metric affects your account, please get in touch with support via contact@metamints.app to get it resolved. It is upgrading the system to recalibrate all old data to serve the policy change to the binary plan."
  );
  useEffect(() => {
    // showModal();
  }, []);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };
  return (
    <>
      <Modal
        style={{ textAlign: "center" }}
        // title="More exciting stuffs are coming !"
        visible={open}
        // onOk={false}
        // onOk={handleOk}
        okButtonProps={{ style: { display: "none" } }} // confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <h1
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            fontWeight: "bold",
          }}
        >
          Notification
        </h1>
        {/* <p>
          We have just upgraded the system for a comprehensive upgrade of system
          commissions. If the new metric affects your account, please get in
          touch with support via{" "}
          <b style={{ fontStyle: "italic" }}> contact@metamints.app</b> to get
          it resolved. It is upgrading the system to recalibrate all old data to
          serve the policy change to the binary plan.
        </p> */}
        <p>
          We do system upgrades and security updates to the platform. User
          please Claim the MTM reward to the wallet and Unstake NFT The end time
          of this operation is 15:00 UTC 11/8/2022. Sorry for the inconvenience!
        </p>
      </Modal>
    </>
  );
};
export default Popup;

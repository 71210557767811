import { ethers } from "ethers";
import { isEmpty, toString } from "lodash";
import { getEllipsisTxt } from "../helpers/formatters";

class BinaryNode {
    constructor(value, leftVolume=0, rightVolume=0) {
        this.value = value;
        this.leftVolume = leftVolume;
        this.rightVolume = rightVolume;
        this.left = null;
        this.right = null;
    }
    setLeft(root) {
        this.left = root;
        return this.left;
    }
    setRight(root) {
        this.right = root;
        return this.right;
    }
    getLeft() {
        return this.left;
    }
    getRight() {
        return this.right;
    }
    parseData() {
        if(this.value === toString(ethers.constants.AddressZero)){
            return {}
        }
        const data = {
            name: getEllipsisTxt(this.value, 3),
            attributes: {
                leftVolume: this.leftVolume,
                rightVolume: this.rightVolume
            }
        }
        if (this.left) {
            const leftTree = this.left.parseData();
            if(!isEmpty(leftTree)){
                data.children = [this.left.parseData()];
            }
        }
        if (this.right) {
            const rightTree = this.right.parseData();
            if(!isEmpty(rightTree)){
                if (data.children) {
                    data.children.push(rightTree);
                } else {
                    data.children = [rightTree];
                }
            }
        }
        return data;
    }
}
export default BinaryNode;
import Constants from "constant"
import { toString } from "lodash";
class APIImplementation {
    constructor() {
        this.DOMAIN = Constants.apiConfig.DOMAIN;
        this.SUB_DOMAIN = Constants.apiConfig.SUB_DOMAIN;
        this.endpoints = Constants.apiConfig.endpoints;
        console.log("Domain: " + this.DOMAIN);
        console.log("Sub domain: " + this.SUB_DOMAIN);
        console.log("Endpoints: " + this.endpoints);
    }
    getVolume = async (address) => {
        try {
            const options = {
                method: "POST",
                body: JSON.stringify({ address }),
            }
            const response = await fetch(`${this.DOMAIN}${this.SUB_DOMAIN}${this.endpoints.GET_VOLUME}`, options);
            const result = await response.json();
            return new Promise((resolve, _) => {
                resolve(result);
            });
        } catch (err) {
            console.error(err);
            return new Promise((_, reject) => {
                reject(err);
            });
        }
    }
    getTreeArray = async (address, contract, contractOwner, isCloned) => {
        try {
            const getTreeUrl = `${this.DOMAIN}${this.SUB_DOMAIN}${this.endpoints.GET_TREE}`;
            const optionGetTree = {
                method: "POST",
                body: JSON.stringify({
                    address,
                    contract,
                    contractOwner,
                    isCloned,
                }),
            };
            const response = await fetch(getTreeUrl, optionGetTree)
            const result = await response.json();
            return new Promise((resolve, _) => {
                resolve(result);
            });
        } catch (err) {
            console.error(err);
            return new Promise((_, reject) => {
                reject(err);
            });
        }
    }
    getBonus = async (address) => {
        try {
            const options = {
                method: "POST",
                body: JSON.stringify({ address }),
            }
            const response = await fetch(`${this.DOMAIN}${this.SUB_DOMAIN}${this.endpoints.GET_BONUS}`, options);
            const result = await response.json();
            return new Promise((resolve, _) => {
                resolve(result);
            });
        } catch (err) {
            console.error(err);
            return new Promise((_, reject) => {
                reject(err);
            });
        }
    }
    updateRewardClaimed = async (address, amountClaimed) => {
        try {
            const options = {
                method: "POST",
                body: JSON.stringify({ address, amountClaimed }),
            }
            const response = await fetch(`${this.DOMAIN}${this.SUB_DOMAIN}${this.endpoints.UPDATE_REWARD_CLAIMED}`, options);
            const result = await response.json();
            return new Promise((resolve, _) => {
                resolve(result);
            });
        } catch (err) {
            console.error(err);
            return new Promise((_, reject) => {
                reject(err);
            });
        }
    }
    getAccount = async (address) => {
        try {
            const options = {
                method: "POST",
                body: JSON.stringify({ address }),
            }
            const response = await fetch(`${this.DOMAIN}${this.SUB_DOMAIN}${this.endpoints.GET_ACCOUNT}`, options);
            const result = await response.json();
            return new Promise((resolve, _) => {
                resolve(result);
            });
        } catch (err) {
            console.error(err);
            return new Promise((_, reject) => {
                reject(err);
            });
        }
    }
    updateVolume = async (address, volume) => {
        console.log("===updateRewardRefs===");
        const url = `${this.DOMAIN}${this.SUB_DOMAIN}${this.endpoints.UPDATE_VOLUME}`;
        const options = {
            method: "POST",
            body: JSON.stringify({
                address: address,
                volume: toString(volume),
            })
        }
        console.log("url: ", url);
        console.log("options", options);
        await fetch(url, options)
    }
}

export default APIImplementation = new APIImplementation();
import {
  Button,
  Col,
  Form,
  Grid,
  Input,
  Layout,
  List,
  Row,
  Typography,
} from "antd";
import Icon from "@ant-design/icons";
import FooterBg from "assets/images/Footer/Footer.png";
import { AiOutlineMedium } from "react-icons/ai";
import {
  FaFacebook,
  FaPhone,
  FaTelegram,
  FaTwitter,
  FaYoutube,
  FaMapPin,
} from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { Link, NavLink, useLocation } from "react-router-dom";
import styles from "./styles.module.css";
import React, { useMemo } from "react";
import LogoHomeIcon from "assets/images/metamints_logo-01.svg";
import { useHistory } from "react-router-dom";
// const LogoHome = (props) => {
//   const history = useHistory();
//   return (
//     <Icon
//       component={LogoHomeIcon}
//       {...props}
//       onClick={() => history.push("/explore")}
//     />
//   );
// };

const { Title } = Typography;
const { Footer } = Layout;
const { useBreakpoint } = Grid;

const data = [
  // { icon: FaPhone, text: "+123456789" },
  { icon: MdMail, text: "contact@metamints.app" },
  {
    icon: FaMapPin,
    text: `703 Admiralty Centre Tower 1, 18 Harcourt Road, Hong Kong`,
  },
];

const MainFooter = () => {
  const [form] = Form.useForm();
  const { lg, md, sm, xs } = useBreakpoint();
  const location = useLocation();
  const isHomePage = useMemo(
    () => location.pathname === "/",
    [location.pathname]
  );

  return (
    <Footer className={styles.footer}>
      {isHomePage && (
        <div className={styles.contacUs}>
          <Row justify="space-between" className={styles.topFooter}>
            {lg ? (
              <>
                <Col span={24} md={24} lg={10} className={styles.leftFooter}>
                  <div
                    className={styles.leftFooterBg}
                    style={{ backgroundImage: `url(${FooterBg})` }}
                  >
                    <List
                      className={styles.listItem}
                      dataSource={data}
                      renderItem={(item) => (
                        <List.Item>
                          <Typography.Text className={styles.contactIcon}>
                            <item.icon />
                          </Typography.Text>{" "}
                          <p>
                            {item.text.substring(0, 29)} <br />{" "}
                            {item.text.substring(29)}
                          </p>
                        </List.Item>
                      )}
                    />
                    <div className={styles.circle}></div>
                    <div className={styles.socialList}>
                      <a href="https://twitter.com/MetamintsOFCL">
                        <FaTwitter />
                      </a>
                      <a href="https://medium.com/@metamints">
                        <AiOutlineMedium />
                      </a>
                      <a href="https://t.me/MetamintsOfficialGroup">
                        <FaTelegram />
                      </a>
                      <a href="https://t.me/MetamintsOfficialChannel">
                        <FaTelegram />
                      </a>
                      <a href="https://www.facebook.com/MetamintsOfficial">
                        <FaFacebook />
                      </a>
                    </div>
                  </div>
                </Col>
                <Col
                  md={24}
                  lg={10}
                  className={styles.rightFooter}
                  style={{ marginTop: md || sm || xs ? 48 : 0 }}
                >
                  <Title level={2} style={{ color: "white" }}>
                    CONTACT US
                  </Title>
                  <Typography.Text style={{ color: "#fff" }}>
                    We are always open and we welcome any questions you have for
                    our teams. If you wish to get in touch, please fill out the
                    form below. Someone from our team will get back to you
                    slowly.
                  </Typography.Text>
                  <Form form={form} layout="vertical" className={styles.form}>
                    <Row gutter={32}>
                      <Col span={12}>
                        <Form.Item label="YOUR NAME">
                          <Input placeholder="Introduce yourself" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="YOUR EMAIL">
                          <Input placeholder="Who do we reply tor" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="YOUR MESSAGE">
                          <Input.TextArea
                            placeholder="Leave your question or comment here"
                            rows={5}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={24}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          // onClick={() => history.push("/explore")}
                          size="large"
                          className={styles.exploreBtn}
                        >
                          Send
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </>
            ) : (
              <>
                <Col
                  md={24}
                  lg={10}
                  className={styles.rightFooter}
                  style={{ marginTop: md || sm || xs ? 48 : 0 }}
                >
                  <Title level={2} style={{ color: "white" }}>
                    CONTACT US
                  </Title>
                  <Typography.Text style={{ color: "#fff" }}>
                    We are always open and we welcome any questions you have for
                    our teams. If you wish to get in touch, please fill out the
                    form below. Someone from our team will get back to you
                    slowly.
                  </Typography.Text>
                  <Form form={form} layout="vertical" className={styles.form}>
                    <Row gutter={32}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item label="YOUR NAME">
                          <Input placeholder="Introduce yourself" />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item label="YOUR EMAIL">
                          <Input placeholder="Who do we reply tor" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="YOUR MESSAGE">
                          <Input.TextArea
                            placeholder="Leave your question or comment here"
                            rows={5}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={24}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          // onClick={() => history.push("/explore")}
                          size="large"
                          className={styles.exploreBtn}
                        >
                          Send
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col span={24} md={24} lg={10} className={styles.leftFooter}>
                  <div
                    className={styles.leftFooterBg}
                    style={{ backgroundImage: `url(${FooterBg})` }}
                  >
                    <List
                      className={styles.listItem}
                      dataSource={data}
                      renderItem={(item) => (
                        <List.Item>
                          <Typography.Text className={styles.contactIcon}>
                            <item.icon />
                          </Typography.Text>{" "}
                          <p>
                            {item.text.substring(0, 29)} <br />{" "}
                            {item.text.substring(29)}
                          </p>
                        </List.Item>
                      )}
                    />
                    <div className={styles.circle}></div>
                    <div className={styles.socialList}>
                      <a href="https://twitter.com/MetamintsOFCL">
                        <FaTwitter />
                      </a>
                      <a href="https://t.me/MetamintsOfficialGroup">
                        <FaTelegram />
                      </a>
                      <a href="https://t.me/MetamintsOfficialChannel">
                        <FaTelegram />
                      </a>
                      <a href="https://www.facebook.com/MetamintsOfficial">
                        <FaFacebook />
                      </a>
                      <a href="https://medium.com/@metamints">
                        <AiOutlineMedium />
                      </a>
                      <FaYoutube />
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </div>
      )}

      <div className={styles.container}>
        <Row className={styles.bottomFooter} justify="space-between">
          <Col>
            <div className={styles.logoHomeWrapper}>
              {/* <LogoHome
                style={{height: '0.5em !important'}}
                // onClick={() => setVisileSubMenu(true)}
                className={styles.iconLogo}
              />{" "} */}
              <img src={LogoHomeIcon} className={styles.iconLogo} />
              {/* <IconArrowDown /> */}
            </div>
          </Col>
          <Col flex="auto" className={styles.menuWrapperCol}>
            <div className={styles.menuWrapper}>
              <div className={styles.menuItems}>
                <NavLink to="/">NFT MARKETPLACE</NavLink>
                <NavLink to="/dex">DEX</NavLink>
                <NavLink to="/staking">STAKING</NavLink>
                <a href="https://docs.metamints.app"> WHITEPAPER</a>
              </div>
              {/* <div className={styles.menuItems}>
              
                <a href="https://docs.metamints.app" rel="noopener noreferrer">
                  Whitepaper
                </a>
              </div> */}
            </div>
          </Col>
        </Row>
      </div>
    </Footer>
  );
};

export default MainFooter;

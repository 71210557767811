import Constants from "constant";
import React, { useState } from "react";
import DashboardLayout from "./DashboardLayout";
import GameDashboardContent from "./GameDashboardContent";

const GameDashboard = () => {
  const [show, setShow] = useState(false);
  let abiStaking, addrStaking, abiCollection, addrCollection, nftPrice;
  abiStaking = JSON.parse(Constants.contracts.STAKING_NFT_ABI);
  addrStaking = Constants.contracts.STAKING_NFT_ADDRESS;
  abiCollection = JSON.parse(Constants.contracts.NFT_COLLECTION_ABI);
  addrCollection = Constants.contracts.NFT_COLLECTION_ADDRESS;
  nftPrice = Constants.NFT_PRICE;
  let contract = {
    abiStaking,
    addrStaking,
    abiCollection,
    addrCollection,
  }
  return (
    <>
      <GameDashboardContent  contract={contract} show={show} setShow={setShow} />

      <DashboardLayout contract={contract} nftPrice = {nftPrice} show={show} setShow={setShow} />
    </>
  );
};

export default React.memo(GameDashboard);

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "../../../styles.module.css";
import { Button } from "antd";
import { useState } from "react";
import { useMoralis } from "react-moralis";
import constants from "constant";
import ModalReferral from "./ModalReferral";
import { isEmpty } from "lodash";
import { failureModal } from "helpers/modals";
import apis from "cores/apis";
import { ethers } from "ethers";
import { checkWalletConnection } from "helpers/auth";
const LayoutItem = ({ contract, item, type, image }) => {
  const { abiStaking, addrStaking, abiCollection, addrCollection } = contract;
  const { Moralis, account, isAuthenticated, authenticate } = useMoralis();
  const { DOMAIN, SUB_DOMAIN, endpoints } = constants.apiConfig;
  const serverURL = process.env.REACT_APP_MORALIS_SERVER_URL;
  const appId = process.env.REACT_APP_MORALIS_APPLICATION_ID;
  Moralis.initialize(appId);
  Moralis.serverURL = serverURL;
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  async function stakeNFT() {
    const tokenId = item?.tokenId.toString()
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const userSigner = await provider.getSigner()
      console.log("User signer", userSigner);
      const contractNFT = new ethers.Contract(addrCollection, abiCollection, userSigner)
      const contractStaking = new ethers.Contract(addrStaking, abiStaking, userSigner)
      console.log("Approve tokenId", tokenId)
      const txApprove = await contractNFT.approve(addrStaking, tokenId);
      await txApprove.wait();
      console.log("Tx approve hash", txApprove.hash);
      console.log("Stake tokenId", tokenId);
      const txStake = await contractStaking.stake([tokenId])
      await txStake.wait();
      console.log("Tx stake hash", txStake.hash);
      console.log("Staking success");
      const isExisted = await accountExisted();
      console.log("isExisted", isExisted);
      if (isExisted) {
        await apis.updateVolume(
          account,
          item.price,
        )
      } else {
        setShowModal(true);
      }
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false);
      const error = { err };
      console.log("Stake Error", error);
      const reason = error?.err?.reason?.toString() ?? error?.err?.message?.toString();
      if (reason?.length !== 0) {
        if (reason === "execution reverted: Can't stake tokens you don't own!") {
          failureModal("Stake Error", "You don't own this nft")
        } else {
          failureModal("Stake Error", reason)
        }

      }
    }
  }
  async function accountExisted() {
    setIsLoading(true);
    const url = `${DOMAIN}${SUB_DOMAIN}${endpoints.GET_ACCOUNT_INFO}`;
    const options = {
      method: "POST",
      body: JSON.stringify({
        address: account,
      })
    }
    console.log("options", options);
    const res = await fetch(url, options);
    const data = await res.json();
    console.log("data", data);
    setIsLoading(false);
    if (data?.code === 200 && !isEmpty(data?.data)) {
      return true;
    }
    return false;
  }
  async function handleStakingClicked() {
    setIsLoading(true);
    await checkWalletConnection(isAuthenticated, authenticate, stakeNFT)
    
  }
  return (
    <div className={clsx([styles.layoutItem, styles[type]])}>
      <span className={styles.code}>{item.code}</span>
      <span className={styles.type}>{item.code}</span>
      <img alt="" src={image} />

      <div className={styles.layoutItemRight}>
        <div>
          <p className={styles.title}>{item.title}</p>
          <p className={styles.description}>{item.description}</p>
        </div>
        {/* <div
          className={clsx("input-text")}
          style={{ marginBottom: 5, marginTop: "auto" }}
        >
          10
        </div> */}
        <Button
          style={{ marginBottom: 5 }}
          className={styles.startStackingBtn}
          loading={isLoading}
          onClick={() => handleStakingClicked()}
          block
        >
          Start Staking
        </Button>

        {/* <Button className={styles.sellOnMpBtn} block>
          Sell on Marketplace
        </Button> */}{
          showModal && <ModalReferral volume={item?.price} />
        }
      </div>
    </div>
  );
};

LayoutItem.propTypes = {
  type: PropTypes.oneOf(["ssr", "sr", "r"]),
};

export default LayoutItem;

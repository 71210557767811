import { ethers } from "ethers";
import { toString } from "lodash";
import apis from "./apis";
import BinaryNode from "./binaryNode";
class BinaryTree {
    constructor(data) {
        this.data = data;
    }
    insertLevelOrder = async (i) => {
        let root;
        if (i < this.data.length) {
            if (this.data[i] === toString(ethers.constants.AddressZero)) {
                root = new BinaryNode(this.data[i]);
            } else {
                const result = await apis.getVolume(this.data[i]);
                if (result.code === 200) {
                    root = new BinaryNode(this.data[i], result.data.leftVolume, result.data.rightVolume);

                } else {
                    root = new BinaryNode(this.data[i]);
                }
            }
            root.setLeft(await this.insertLevelOrder(2 * i + 1));
            root.setRight(await this.insertLevelOrder(2 * i + 2));
        }
        return root;
    }
}
export default BinaryTree;
// rafce
import { PlusOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal } from 'antd'
import Constants from 'constant';
import { checkWalletConnection } from 'helpers/auth';
import { failureModal, successModal } from 'helpers/modals';
import { isEmpty, toString } from 'lodash';
import React, { useState } from 'react'
import { useMoralis } from 'react-moralis';
import styles from "../../../styles.module.css";

function ModalReferral({ volume }) {

    const { account, authenticate, isAuthenticated } = useMoralis();
    const [isLoadingLink, setIsLoadingLink] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const [open, setOpen] = useState(true);
    const { DOMAIN, SUB_DOMAIN, endpoints } = Constants.apiConfig;
    const handleCreateLinkClick = async () => {
        await checkWalletConnection(isAuthenticated, authenticate, createLink);
        window.location.reload();
    };
    const handleAddReferralSubmit = async (value) => {
        console.log("value", value);
        await addReferral(value?.referralLink);
        setOpen(false);
        window.location.reload();
    };
    const handleContinueClick = async () => {
        setOpen(false);
        window.location.reload();
    };
    const createLink = async () => {
        setIsLoadingLink(true);
        try {
            // const DOMAIN = "http://localhost:7000/";
            const saveAccountUrl = `${DOMAIN}${SUB_DOMAIN}${endpoints.SAVE_ACCOUNT}`;
            const body = {
                address: account,
                volume: toString(volume),
            };
            const optionSaveAccount = {
                method: "POST",
                body: JSON.stringify(body),
            };
            console.log("Save Account URL", saveAccountUrl);
            console.log("Save Account Option", optionSaveAccount);
            await fetch(saveAccountUrl, optionSaveAccount)
            setOpen(false);
            setIsLoadingLink(false);
        } catch (error) {
            console.error(error);
            failureModal("Error", "Something went wrong");
            setIsLoadingLink(false);
        }
    };
    const addReferral = async (referralLink) => {
        const address = account;
        const linkParts = referralLink.split("/");
        console.log("Link Parts", linkParts);
        const code = linkParts[linkParts.length - 1];
        const side = linkParts[linkParts.length - 2];
        const saveAccountUrl = `${DOMAIN}${SUB_DOMAIN}${endpoints.SAVE_ACCOUNT}`;
        if (side !== "left" && side !== "right") {
            failureModal("Invalid side link. Recheck your link");
            return;
        }
        if (isEmpty(code)) {
            failureModal("Missing invitation code. Recheck your link");
            return;
        }
        const body = {
            address: address,
            code,
            isLeft: side === "left" ? true : false,
            volume: toString(volume),
        };
        const optionSaveAccount = {
            method: "POST",
            body: JSON.stringify(body),
        };
        console.log("Save Account URL", saveAccountUrl);
        console.log("Save Account Option", optionSaveAccount);
        await fetch(saveAccountUrl, optionSaveAccount)
        setOpen(false);
    };
    return (
        <Modal
            style={{ textAlign: "center" }}
            visible={open}
            okButtonProps={{ style: { display: "none" } }}
            // confirmLoading={confirmLoading}
            cancelButtonProps={{ style: { display: "none" } }}
            onCancel={() => setOpen(false)}
        >
            <h1
                style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    fontWeight: "bold",
                }}
            >
                Staking
            </h1>
            <div className={styles.optionMain}>
                <div>
                    <Button
                        onClick={handleCreateLinkClick}
                        loading={isLoadingLink}
                        icon={
                            <PlusOutlined
                                className={styles.iconPl}
                                style={{
                                    textAlign: "center",
                                }}
                            />
                        }
                        className={styles.plusBtn}
                        style={{
                            borderRadius: "8px",
                            border: "2px solid #27aae1",
                        }}
                    ></Button>

                    <span style={{ marginLeft: "10px" }}>Create new binary tree</span>
                </div>
                <div>
                    <Button
                        onClick={() => setShowInput((showInput) => !showInput)}
                        icon={
                            <PlusOutlined
                                className={styles.iconPl}
                                style={{
                                    textAlign: "center",
                                }}
                            />
                        }
                        className={styles.plusBtn}
                        style={{
                            borderRadius: "8px",
                            border: "2px solid #27aae1",
                        }}
                    ></Button>
                    <span style={{ marginLeft: "10px" }}>Add referral link</span>
                </div>
                {showInput &&
                    <Form
                        layout='inline'
                        onFinish={handleAddReferralSubmit}
                        autoComplete="off"
                        size='medium'
                    >
                        <Form.Item
                            name="referralLink"
                            rules={[{ required: true }, { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                        >
                            <Input
                                placeholder="Enter referral link"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>

                    </Form>}
                <div>
                    <Button
                        style={{
                            borderRadius: "8px",
                            border: "2px solid #27aae1",
                        }}
                        className={styles.couBtn}
                        icon={
                            <RightOutlined
                                className={styles.iconPl}
                                style={{
                                    textAlign: "center",
                                }}
                            />
                        }
                        onClick={handleContinueClick}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ModalReferral
import {
  marketplaceABI,
  marketplaceAddress,
} from "./contractsInfo/marketplace";
import {
  nftCollectionABI,
  nftCollectionAddress,
} from "./contractsInfo/nftCollection";
import { stakingNFTABI, stakingNFTAddress } from "./contractsInfo/staking-nft";
import {
  authorityABI,
  authorityAddress,
} from "./contractsInfo/authority";
import {
  treasuryABI,
  treasuryAddress,
} from "./contractsInfo/treasury";
import {
  swapAddress
} from "./contractsInfo/swap";
import provider from "./provider";
import { tokenABI, tokenAddress } from "./contractsInfo/tokenERC20";
import tokens from "./tokens";
const TARGET_TOKEN = "BUSD";
const Constants = {
  TARGET_TOKEN: tokens[TARGET_TOKEN],
  contracts: {
    MARKETPLACE_ABI: marketplaceABI,
    MARKETPLACE_ADDRESS: marketplaceAddress,
    NFT_COLLECTION_ABI: nftCollectionABI,
    NFT_COLLECTION_ADDRESS: nftCollectionAddress,
    STAKING_NFT_ABI: stakingNFTABI,
    STAKING_NFT_ADDRESS: stakingNFTAddress,
    TOKEN_ERC20_ABI: tokenABI,
    TOKEN_ERC20_ADDRESS: tokenAddress,
    AUTHORITY_ABI: authorityABI,
    AUTHORITY_ADDRESS: authorityAddress,
    TREASURY_ABI: treasuryABI,
    TREASURY_ADDRESS: treasuryAddress,
    SWAP_ADDRESS: swapAddress,
  },
  token: {
    TOKEN_SYMBOL: "MTM",
    TOKEN_NAME: "Metamints",
    DECIMALS: 18,
    WALLET: "0x3c280c4BD095fbea544Fe4a1b82b61C08e2C3f6C",
  },
  collections: {
    COLLECTIONS_NAME: "Metamints",
    COLLECTIONS_SYMBOL: "MTM",
    COLLECTIONS_URI: "ipfs://QmcPccuccWbtewn7bwpxw3rhWuuT77NSXyg4ncYEGycYNg/",
    types: {
      FREE: "free",
      CHARGE: "charge",
    },
  },
  NFT_PRICE: 300,
  rewardPolicy: {
    custom: {
      F_1: 0.08,
      F_2: 0.05,
      F_3: 0.03,
      F_4: 0.02,
      F_5: 0.05,
    },
    default: 0,
  },
  storageBucket: {
    CHARGE_BUCKET: "https://storage.googleapis.com/ai_robot_bucket/",
    FREE_BUCKET: "https://storage.googleapis.com/free_nft_bucket/",
    FREE_NFT_IMAGE: "free.png",
  },
  BUSD_EXCHANGE_RATE: 0.05,
  GATEWAY_HOSTNAME: "myipfs.mypinata.cloud",
  GATEWAY: "https://myipfs.mypinata.cloud",
  apiConfig: {
    DOMAIN: process.env.REACT_APP_DOMAIN_NAME ?? "https://metamints.app/",
    SUB_DOMAIN: ".netlify/functions/",
    endpoints: {
      CLAIM: "claim",
      GET_TREE: "getTree",
      SAVE_ACCOUNT: "saveAccount-background",
      GET_ACCOUNT: "getAccount",
      GET_ACCOUNT_INFO: "getAccountInfo",
      UPDATE_VOLUME: "updateVolume-background",
      CLAIM_BONUS: "claimBonus",
      GET_BONUS: "getBonus",
      GET_VOLUME: "getVolume",
      UPDATE_REWARD_CLAIMED: "updateRewardClaimed",
    },
  },
  staking: {
    duration: {
      VALUE: 9,
      UNIT: "month", // day, month, year, hour, minute, second
    },
  },
  pagination: {
    PAGE_SIZE: 10,
  },
  provider,
};

export default Constants;

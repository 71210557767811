import { Button, Grid } from 'antd';
import React, { useState, useEffect } from 'react';
import styles from '../../../styles.module.css';

import clsx from 'clsx';
import StackSvg from '../StackSvg';
import { useMoralis } from 'react-moralis';
import Constants from 'constant';
import { ethers } from 'ethers';
const { useBreakpoint } = Grid;
const DashboardLayoutHeader = ({ contract, setShow, extraCn, show }) => {
  const { account, isAuthenticated } = useMoralis();
  const { abiCollection, addrCollection } = contract;
  const { md } = useBreakpoint();
  const [total, setTotal] = useState(0);
  // const [totalMyNFTs, setTotalMyNFTs] = useState(0);

  // async function getNFTBalance(smNFT) {
  //     let balance = await smNFT.totalSupply();
  //     setTotal(balance);
  // }
  async function getMyNft(smNFT) {
    let balance = await smNFT.balanceOf(account);
    console.log("balance", balance?.toString());
    setTotal(balance?.toString());
  }

  useEffect(() => {
    if (isAuthenticated && account) {
      const smNFT = new ethers.Contract(addrCollection, ["function balanceOf(address account) external view returns (uint256)"], Constants.provider);
      console.log("smNFT", smNFT)
      getMyNft(smNFT);
    } else {
      setTotal(0);
    }
  }, [abiCollection, account, addrCollection, isAuthenticated]);

  return (
    <div className={clsx(styles.gameLayoutHeader, extraCn)}>
      <div className={styles.gameLayoutHeaderItem}>
        <p>${Constants.token.TOKEN_SYMBOL} Price</p>
        <div className={clsx('input-text')}>1 {Constants.token.TOKEN_SYMBOL} = {Constants.BUSD_EXCHANGE_RATE} {Constants.TARGET_TOKEN.symbol}</div>
      </div>

      <div className={styles.gameLayoutHeaderItem}>
        <p>My Total NFTs</p>
        <div className={clsx('input-text')}>{total}</div>
      </div>

      {!md && (
        <div className={styles.gameLayoutHeaderItem} style={{ flex: 0 }}>
          <p style={{ opacity: 0 }}>a</p>
          <Button
            style={{
              ...(show
                ? { color: 'var(--theme-color)', background: '#fff' }
                : {
                  color: '#fff',
                  background:
                    'linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color-bold) 100%)',
                }),
              border: 'none',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => setShow((prev) => !prev)}
            icon={<StackSvg />}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardLayoutHeader;

import MainBanner from "./MainBanner/MainBanner";
import CategoryList from "./CategoryList/CategoryList";
import NotableSlider from "./NotableSlider";
import AutoSlide from "./AutoSlide";
import ExploreSlide from "./ExploreSlide";
import Popup from "./Popup";

export default function Home({ isInvite }) {
  return (
    <div>
      <Popup />
      <MainBanner />
      <NotableSlider />
      <ExploreSlide />
      <CategoryList />
      <AutoSlide />
    </div>
  );
}

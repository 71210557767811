export const checkWalletConnection = async (isAuthenticated, authenticate, callback) => {
    try{
        if(!isAuthenticated){
            authenticate()
        }
        await callback()
    }catch(error){
        console.error(error)
        return new Promise((resolve, reject) => reject(error))
    }
}
import clsx from "clsx";
import React, { useEffect } from "react";
import styles from "../../../styles.module.css";
import DashboardLayoutHeader from "./DashboardLayoutHeader";
import LayoutItem from "./LayoutItem";
import { useMoralis } from 'react-moralis';
import { useState } from "react";
import axios from "axios";
import Constants from "constant";
import { ethers } from "ethers";
import IPFSGatewayTools from "@pinata/ipfs-gateway-tools/dist/browser";
import ModalReferral from "./ModalReferral";

const DashboardLayout = ({ contract, nftPrice, setShow, show }) => {
  const { abiStaking, addrStaking, abiCollection, addrCollection } = contract
  const { account, isAuthenticated } = useMoralis();
  const [NFTs, setNFTs] = useState([]);
  const gatewayTools = new IPFSGatewayTools();

  const getNFTs = async () => {
    try {
      if (account) {
        const nftContract = new ethers.Contract(addrCollection,
          [
            "function balanceOf(address account) external view returns (uint256)",
            "function tokenOfOwnerByIndex(address owner, uint256 index) public view returns (uint256)",
            "function tokenURI(uint256 tokenId) external view returns (string memory)"
          ]
          , Constants.provider);
        console.log("NFT contract", nftContract)
        console.log("account", account);
        console.log("addrCollection", addrCollection);
        let balance = await nftContract.balanceOf(account);
        console.log("balance", balance?.toString());
        for (let i = 0; i < balance; i++) {
          let tokenId = (await nftContract.tokenOfOwnerByIndex(account, i)).toString();
          let tokenURI = (await nftContract.tokenURI(tokenId)).toString();
          if (gatewayTools.containsCID(tokenURI).containsCid) {
            tokenURI = gatewayTools.convertToDesiredGateway(tokenURI, Constants.GATEWAY);
          }
          console.log("tokenURI", tokenURI);
          const metadata = (await axios.get(tokenURI)).data;
          if (metadata) {
            let item = {}
            let linkImage = metadata.image.replace('ipfs://', '');
            let arrStr = linkImage.split('/');
            let nameImage, bucket;
            bucket = Constants.storageBucket.CHARGE_BUCKET;
            nameImage = arrStr[1];

            // check token is staked before
            const stakingContract = new ethers.Contract(addrStaking, abiStaking, Constants.provider);
            const isStakedBefore = await stakingContract.isStaked(tokenId);
            item = {
              image: bucket + nameImage,
              description: metadata.description,
              tokenId: tokenId,
              name: metadata.name,
              isStakedBefore: isStakedBefore,
              price: nftPrice,
            }

            console.log("item", item);
            setNFTs((NFTs) => [...NFTs, item]);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }

  };
  useEffect(() => {
    if (isAuthenticated && account) {
      setNFTs([]);
      getNFTs();
    } else {
      setNFTs([]);
    }
  }, [account, isAuthenticated]);
  return (
    <div
      className={clsx(styles.gameLayout, styles.gameDashboardLayout, {
        [styles.show]: show,
      })}>
      <DashboardLayoutHeader
        contract={contract}
        show={show}
        setShow={setShow}
        extraCn={styles.gameDashboardHeaderDesktop}
      />

      <div className={clsx(styles.gameLayoutBody, styles.dasboardLayoutBody)}>
        {

          NFTs.map((e, index) => (
            <LayoutItem
              key={index}
              item={{
                title: e.name,
                description: e.description,
                code: "#" + e.tokenId,
                tokenId: e.tokenId,
                isStakedBefore: e.isStakedBefore,
                price: e.price,
                owner: {
                  name: e.owner,
                },
              }}
              type={e.type ? e.type.toLowerCase() : 'sr'}
              image={e.image}
              contract={contract}
            />
          ))

        }

      </div>
      {/* <ModalReferral volume={nftPrice} /> */}
    </div>
  );
};

export default React.memo(DashboardLayout);

import { CreditCardOutlined } from "@ant-design/icons";
import { Button, Input, notification } from "antd";
import Text from "antd/lib/typography/Text";
import Constants from "constant";
import { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import AddressInput from "../../AddressInput";
import AssetSelector from "./AssetSelector";
import transferStyles from "./transferStyles.module.css";
import constants from "constant";
import { ethers } from "ethers";
const styles = {
  card: {
    alignItems: "center",
    width: "100%",
  },
  header: {
    textAlign: "center",
  },
  input: {
    width: "100%",
    outline: "none",
    fontSize: "16px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textverflow: "ellipsis",
    appearance: "textfield",
    color: "#041836",
    fontWeight: "700",
    border: "none",
    backgroundColor: "transparent",
  },
  select: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
  },
  textWrapper: { maxWidth: "80px", width: "100%" },
  row: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    flexDirection: "row",
  },

  title: {
    fontWeight: "bold",
    color: "var(--theme-color-bold)",
  },
};

function Transfer() {
  const { Moralis } = useMoralis();
  const [receiver, setReceiver] = useState();
  const [asset, setAsset] = useState();
  const [tx, setTx] = useState();
  const [amount, setAmount] = useState();
  const [isPending, setIsPending] = useState(false);
  const [usdTranferAmount, setUsdTranferAmount] = useState(0);
  const [isVisibleUSD, setIsVisibleUSD] = useState(false);
  const tokenWallet = constants.token.WALLET;
  useEffect(() => {
    asset && amount ? setTx({ amount, receiver: tokenWallet, asset }) : setTx();

    if (asset && asset.token_address === Constants.contracts.TOKEN_ERC20_ADDRESS.toLowerCase()) {
      setIsVisibleUSD(true)
      const usdTranfer = amount ? amount * Constants.BUSD_EXCHANGE_RATE : 0;
      console.log(usdTranfer);
      setUsdTranferAmount(usdTranfer);
    }
    else {
      setIsVisibleUSD(false)
    }
  }, [asset, amount]);
  const openNotification = ({ message, description }) => {
    notification.open({
      placement: "bottomRight",
      message,
      description,
      onClick: () => {
        console.log("Notification Clicked!");
      },
    });
  };

  async function transfer() {
    try{
      const { amount } = tx;
      const swapAddress = Constants.contracts.SWAP_ADDRESS;
      const amountInEth = ethers.utils.parseEther(amount.toString()).toString();
      console.log("Amount", amount)
      console.log("Amount In Eth", amountInEth);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      console.log("====Approve====")
      const contractToken = new ethers.Contract(
        Constants.contracts.TOKEN_ERC20_ADDRESS,
        ["function approve(address spender, uint256 amount) external returns (bool)"],
        signer
      )
      const txApprove = await contractToken.approve(swapAddress, amountInEth);
      await txApprove.wait();
      console.log("Tx approve", txApprove.hash);
      console.log("====Swap====")
      const contractSwap = new ethers.Contract(
        swapAddress,
        ["function swapToFiat(uint256 value_) external"],
        signer
      )
      const txSwap = await contractSwap.swapToFiat(amountInEth);
      await txSwap.wait();
      console.log("Tx swap", txSwap.hash);
      openNotification({
        message: "Transfer Success",
        description: `Transaction hash: ${txSwap.hash}`,
      })
    } catch (error) {
      console.error("Swap error",error);
      openNotification({
        message: "Transfer Failed",
        description: error?.message,
      })
    }
  }

  return (
    <div style={styles.card}>
      <div style={styles.tranfer}>
        <div style={styles.header}>
          <h3 style={styles.title}>Transfer Assets</h3>
        </div>
        <div style={styles.select}>
          <div style={styles.textWrapper}>
            <Text strong>Address:</Text>
          </div>
          <AddressInput autoFocus onChange={setReceiver} />
        </div>
        <div style={styles.select}>
          <div style={styles.textWrapper}>
            <Text strong>Amount:</Text>
          </div>
          <Input
            size="large"
            prefix={<CreditCardOutlined />}
            onChange={(e) => {
              setAmount(`${e.target.value}`);
            }}
          />
        </div>
        <div style={styles.select}>
          <div style={styles.textWrapper}>
            <Text strong>Asset:</Text>
          </div>
          <AssetSelector setAsset={setAsset} style={{ width: "100%" }} />
        </div>
        {isVisibleUSD && (
          <div style={styles.select}>
            <div style={styles.textWrapper}>
              <Text type="secondary">USD:</Text>
            </div>
            <div>
              <Text type="secondary">${usdTranferAmount.toFixed(5)}</Text>
            </div>
          </div>
        )
        }
        <Button
          type="text"
          size="large"
          loading={isPending}
          className={
            !tx ? transferStyles.transferBtn2 : transferStyles.transferBtn
          }
          onClick={transfer}
          disabled={!tx}
        >
          Transfer
        </Button>
      </div>
    </div>
  );
}

export default Transfer;

import { Button, Modal, Statistic } from "antd";
import React from "react";
import styless from "./Explores.module.css";
// import imgFake from 'assets/images/img-explore.png';
import ManahubsBoxGif from "assets/images/metamint-box.gif";
import btnstyles from "./MysteryBox.module.css";
import { useState } from "react";
import { useMoralis } from "react-moralis";
import constants from "constant";
import { useParams } from "react-router-dom";
import Constants from "constant";
import { getCollectionByAddress } from "helpers/collection";
import { checkWalletConnection } from "helpers/auth";
import { failureModal, successModal } from "helpers/modals";
import { ethers } from "ethers";

let clickBuy = false;
let checkInstallMetamask = true;
let system = {};
const Cardbox = () => {
  const { address } = useParams();
  const { chainId } = useMoralis();
  const collection = getCollectionByAddress(address, chainId);

  const { Countdown } = Statistic;
  const deadline = new Date("2022-09-19T23:30:00+07:00"); // Hong Kong timezone
  const [amount, setAmount] = useState(1);
  let flag = false;
  if (Date.now() >= deadline) {
    flag = true;
  }

  const [isNFTSale, setIsNFTSale] = useState(flag);
  const [format, setFormat] = useState(
    "D [days] H [hours] m [minutes] s [seconds]"
  );

  const handleChange = (event) => {
    const result = event.target.value.replace(/\D/g, "");
    let maxAmount = system.dragonMax - system.curTokenId;
    if (parseInt(result) > maxAmount) {
      setAmount(maxAmount);
    } else if (parseInt(result) < 1 || result === "") {
      setAmount(1);
    } else {
      setAmount(result);
    }
  };
  const [loading, setLoading] = useState(false);
  let { Moralis, authenticate, account, isAuthenticated } = useMoralis();
  const serverUrl = process.env.REACT_APP_MORALIS_SERVER_URL;
  const appId = process.env.REACT_APP_MORALIS_APPLICATION_ID;
  Moralis.start({ serverUrl, appId });

  const nftABIJson = JSON.parse(constants.contracts.NFT_COLLECTION_ABI);
  const nftAddr = constants.contracts.NFT_COLLECTION_ADDRESS;
  if (checkInstallMetamask && document.getElementById("walletConnectAlert")) {
    setTimeout(async () => {
      checkInstallMetamask = false;
      if (typeof window.ethereum !== "undefined") {
        // console.log('MetaMask is installed!');
        checkAuth();
      } else {
        failPurchase("You need to install wallet to use this page!");
        window.open("https://metamask.io/");
      }
    }, 200);
  }
  async function checkAuth() {
    if (!account && document.getElementById("walletConnectAlert")) {
      document.getElementById("walletConnectAlert").click();
      // const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      // account = accounts[0];

      // account = req[0];
    }
  }

  // const transferBUSD = async () => {
  //   setLoading(true);
  //   try {
  //     console.log("amount", amount);
  //     const val = multiply(Number(amount), constants.NFT_PRICE);
  //     console.log("val", val);
  //     const options = {
  //       type: "erc20",
  //       amount: Moralis.Units.Token(val.toString(), 18),
  //       contractAddress: constants.TARGET_TOKEN.address,
  //       receiver: constants.token.WALLET,
  //       awaitReceipt: false,
  //     };
  //     console.log("options", options);
  //     const txStatus = await Moralis.transfer(options);
  //     txStatus
  //       .on("transactionHash", (hash) => {
  //         alert("New Transaction hash: " + hash);
  //       })
  //       .on("receipt", (receipt) => {
  //         successModal("🎉 Transaction Success", receipt.transactionHash);
  //         setLoading(false);
  //       })
  //       .on("error", (error) => {
  //         failureModal("❌ Transaction Failed", error.message);
  //         setLoading(false);
  //       });
  //   } catch (err) {
  //     console.error("Error: ", err.message);
  //     failureModal("❌ Transaction Failed", err.message);
  //     setLoading(false);
  //   }
  // };
  const mintNFT = async () => {
    try {
      if (isAuthenticated && account) {
        setLoading(true);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await provider.getSigner();
        console.log("signer", signer)
        const pmtTokenContract = new ethers.Contract(
          constants.TARGET_TOKEN.address,
          JSON.parse(constants.TARGET_TOKEN.abi),
          signer
        );
        console.log("====Approve====")
        const tokenPrice = ethers.utils.parseEther((amount * constants.NFT_PRICE).toString()).toString();
        console.log("TokenPrice", tokenPrice)
        const txApprove = await pmtTokenContract.approve(nftAddr, tokenPrice);
        await txApprove.wait();
        console.log("txApprove hash", txApprove.hash)
        console.log("====Mint====")
        const nftContract = new ethers.Contract(
          nftAddr,
          ["function mint(address _to, uint256 _mintAmount) external"],
          signer
        );
        console.log("nftContract", nftContract)
        console.log("account", account)
        console.log("amount", amount)
        const txMint = await nftContract.mint(account, amount.toString());
        await txMint.wait();
        console.log("txMint hash", txMint.hash)
        succPurchase();
      }
    } catch (error) {
      console.error("Error: ", error.message);
      setLoading(false);
      failureModal("❌ Transaction Failed",
        "Please try again!. Reason: " + error?.data?.message ||
        error?.message ||
        `Internal server error`
      );
    }
  }
  async function handleMintClicked() {
    let enable = true;
    if (enable) {
      if (!account || !isAuthenticated) {
        failPurchase("You need connection to wallet");
      } else {
        if (!clickBuy) {
          clickBuy = true;
          if (!amount || amount === 0) {
            setAmount(1);
          }
          console.log("isCollectionFree", isCollectionFree())
          await checkWalletConnection(isAuthenticated, authenticate, mintNFT)
        }
      }
    }
  }
  function pre() {
    // getMaxTokenId();
    let newAmount = Number(amount) - 1;
    if (newAmount < 1) {
      setAmount(1);
    } else {
      setAmount(newAmount);
    }
  }
  function next() {
    // getMaxTokenId();
    let newAmount = Number(amount) + 1;
    let maxAmount = system.dragonMax - system.curTokenId;

    if (newAmount > maxAmount) {
      setAmount(maxAmount);
    } else {
      setAmount(newAmount);
    }
  }
  function succPurchase() {
    let secondsToGo = 5;
    const modal = Modal.success({
      title: "Success!",
      content: `You have buy this NFT`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  function failPurchase(error) {
    let secondsToGo = 5;
    const modal = Modal.error({
      title: "Error!",
      // content: `There was a problem when buy this NFT`
      content: error + "",
    });
    clickBuy = false;
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }
  function handleChangeTimeCountDown(time) {
    if (time <= 60 * 60 * 24 * 1000) {
      setFormat("H [hours] m [minutes] s [seconds]");
    }
  }
  function handleFinishCountDown() {
    setIsNFTSale(true);
  }
  function isCollectionFree() {
    return collection.type === Constants.collections.types.FREE;
  }
  return (
    <div className={styless.cardbox}>
      <div
        className={styless.image}
        style={{ backgroundImage: `url(${ManahubsBoxGif})` }}
      >
        {/* <Avatar src={item.image} className={styless.avatar} size={80} /> */}
      </div>
      <div className={styless.content}>
        <div className={styless.title} style={{ fontFamily: "GILROY " }}>
          Magic Box
        </div>
        <div className={styless.byAuthor} style={{ fontFamily: "GILROY " }}>
          by <span style={{ fontFamily: "GILROY " }}>Metamints</span>
        </div>
        {isNFTSale ? (
          <>
            <form>
              {!isCollectionFree() && (
                <Button
                  className={btnstyles.exploreBtn}
                  loading={loading}
                  onClick={() => pre()}
                  style={{
                    fontFamily: "GILROY ",
                    width: "15%",
                    textAlign: "center",
                    margin: " 10px",
                    color: " #ff9900",
                    border: "solid 1px #0e0a35",
                    borderRadius: "10px",
                    fontSize: "medium",
                  }}
                >
                  -
                </Button>
              )}
              <input
                type="text"
                id="inputAmount"
                placeholder="Amount"
                pattern="[0-9]*"
                style={{
                  width: "20%",
                  height: "25px",
                  textAlign: "center",
                  margin: " 10px",
                  color: "var(--theme-color)",
                  border: "solid 1px #0e0a35",
                  borderRadius: "10px",
                  fontSize: "medium",
                }}
                value={amount}
                onChange={handleChange}
                disabled={isCollectionFree()}
              />
              {!isCollectionFree() && (
                <Button
                  className={btnstyles.exploreBtn}
                  loading={loading}
                  onClick={() => next()}
                  style={{
                    fontFamily: "GILROY ",
                    width: "15%",
                    textAlign: "center",
                    margin: " 10px",
                    color: " #ff9900",
                    border: "solid 1px #0e0a35",
                    borderRadius: "10px",
                    fontSize: "medium",
                  }}
                >
                  +
                </Button>
              )}
            </form>
            <Button
              className={btnstyles.exploreBtn}
              loading={loading}
              onClick={() => handleMintClicked()}
              style={{ fontFamily: "GILROY " }}
              visible={isNFTSale}
            // disabled = {!isCollectionFree()}
            >
              {isCollectionFree() ? "CLAIM" : "BUY"}
            </Button>
          </>
        ) : (
          <>
            <div
              className={styless.description}
              style={{ fontFamily: "GILROY " }}
            >
              NFT Sale is Live after:
            </div>
            <Countdown
              onChange={handleChangeTimeCountDown}
              onFinish={handleFinishCountDown}
              value={deadline}
              format={format}
              valueStyle={{
                fontSize: "16px",
                fontWeight: "regular",
                textAlign: "center",
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Cardbox;

import { Button, Col, Space, Dropdown, Grid, Layout, Menu, Row } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useChain, useMoralis } from "react-moralis";
import { Link, Redirect, useLocation } from "react-router-dom";
import { AvaxLogo, BSCLogo, ETHLogo, PolygonLogo } from "../Chains/Logos";
import { HambugerBar } from "./IconHeader";
import RoutingMenu from "./RoutingMenu";
import styles from "./styles.module.css";
import DexLogo from "components/Icons/DexLogo";
import GameLogo from "components/Icons/GameLogo";
import MarketplaceLogo from "components/Icons/MarketplaceLogo";

import LogoHomeIcon from "assets/images/metamints_logo-01.svg";
import { useHistory } from "react-router-dom";
// const LogoHome = (props) => {
//   const history = useHistory();
//   return (
//     <Icon
//       component={LogoHomeIcon}
//       {...props}
//       onClick={() => history.push("/explore")}
//     />
//   );
// };
const { Header } = Layout;
const { useBreakpoint } = Grid;

const customStyles = {
  item: {
    display: "flex",
    alignItems: "center",
    height: "45px",
    fontWeight: "500",
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    padding: "0 20px",
  },
  button: {
    border: "2px solid rgb(231, 234, 243)",
    borderRadius: "12px",
    // marginLeft: "30px",
  },
};

const menuItems = [
  {
    key: "0x1",
    value: "Ethereum",
    icon: <ETHLogo />,
  },
  {
    key: "0x539",
    value: "Local Chain",
    icon: <ETHLogo />,
  },
  {
    key: "0x3",
    value: "Ropsten Testnet",
    icon: <ETHLogo />,
  },
  {
    key: "0x4",
    value: "Rinkeby Testnet",
    icon: <ETHLogo />,
  },
  {
    key: "0x2a",
    value: "Kovan Testnet",
    icon: <ETHLogo />,
  },
  {
    key: "0x5",
    value: "Goerli Testnet",
    icon: <ETHLogo />,
  },
  {
    key: "0x38",
    value: "Binance",
    icon: <BSCLogo />,
  },
  {
    key: "0x61",
    value: "Smart Chain Testnet",
    icon: <BSCLogo />,
  },
  {
    key: "0x89",
    value: "Polygon",
    icon: <PolygonLogo />,
  },
  {
    key: "0x13881",
    value: "Mumbai",
    icon: <PolygonLogo />,
  },
  {
    key: "0xa86a",
    value: "Avalanche",
    icon: <AvaxLogo />,
  },
  {
    key: "0xa869",
    value: "Avalanche Testnet",
    icon: <AvaxLogo />,
  },
];

function MainHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const [visileSubMenu, setVisileSubMenu] = useState(false);
  // const history = useHistory();
  const { pathname } = useLocation();

  const { chainId } = useChain();
  // const { isAuthenticated } = useMoralis();
  const [selected, setSelected] = useState({});
  const { isAuthenticated, account } = useMoralis();

  useEffect(() => {
    const defaultChain = menuItems.find((item) => item.key === "0x38");
    if (!chainId || !isAuthenticated || !account) {
      setSelected(defaultChain);
    } else {
      const newSelected = menuItems.find((item) => item.key === chainId);
      setSelected(newSelected);
    }
  }, [chainId, isAuthenticated, account]);

  const menu = (
    <Menu>
      <Menu.Item
        key="marketplace"
        icon={<MarketplaceLogo className="icon-logo" />}
      >
        <Link to="/" className={styles.textLink}>
          Marketplace
        </Link>
      </Menu.Item>
      <Menu.Item key="dex" icon={<DexLogo className="icon-logo" />}>
        <Link to="/dex" className={styles.textLink}>
          Dex
        </Link>
      </Menu.Item>
      <Menu.Item key="staking" icon={<GameLogo className="icon-logo" />}>
        <Link to="/staking" className={styles.textLink}>
          Staking
        </Link>
      </Menu.Item>
    </Menu>
  );

  const { sm, md, xs } = useBreakpoint();

  useEffect(() => {
    if (md && !isOpen) {
      setIsOpen(true);
    }
  }, [md, isOpen]);

  const handleClickSubMenu = () => {
    setVisileSubMenu(false);
  };

  const routePage = useMemo(() => {
    if (pathname.includes("staking")) return "staking";
    if (pathname.includes("dex")) return "dex";
    return "marketplace";
  }, [pathname]);

  return (
    <>
      <Header theme="dark" className={styles.header}>
        <Row className={styles.topBg} wrap>
          <div
            className={`${styles.topHeader} ${!md ? styles.topHeaderMd : ""}`}
          >
            <Col xs={6} sm={6} md={15} lg={12}>
              {/* <Col xs={6} sm={6} md={9} lg={8}> */}
              <div className={!md || xs || sm ? styles.hambugerWrapper : ""}>
                {!md && (
                  <HambugerBar onClick={() => setIsOpen((val) => !val)} />
                )}
                <div
                  className={`${styles.topMenuWrapper} ${
                    !md ? styles.topMenuMdWrapper : ""
                  }`}
                >
                  {/* {(md || routePage === "marketplace") && (
                    <div className={styles.topMenuIcon}>
                      <Link to="/">
                        <div className={styles.topMenuIcon}>
                          <NFTMenu />
                        </div>
                      </Link>

                      {md && routePage === "marketplace" && (
                        <Dot className={styles.topMenuIconDot} />
                      )}
                    </div>
                  )}

                  {(md || routePage === "dex") && (
                    <div className={styles.topMenuIcon}>
                      <Link to="/dex">
                        <div className={styles.topMenuIcon}>
                          <DexMenu />
                        </div>
                      </Link>

                      {md && routePage === "dex" && (
                        <Dot className={styles.topMenuIconDot} />
                      )}
                    </div>
                  )}

                  {(md || routePage === "staking") && (
                    <div className={styles.topMenuIcon}>
                      <Link to="/staking">
                        <div className={styles.topMenuIcon}>
                          <GameMenu />
                        </div>
                      </Link>

                      {md && routePage === "staking" && (
                        <Dot className={styles.topMenuIconDot} />
                      )}
                    </div>
                  )} */}
                  {md && (
                    <div className={styles.logoHomeWrapper}>
                      {/* <LogoHome
                        // onClick={() => setVisileSubMenu(true)}
                        className={styles.iconLogo}
                      />{" "} */}
                      <img src={LogoHomeIcon} className={styles.iconLogo} />
                      {/* <IconArrowDown /> */}
                    </div>
                  )}
                  {md && (
                    <Dropdown
                      overlay={menu}
                      className={`${styles.menuDropdown} ${styles.dropdown}`}
                    >
                      <Button>
                        <Space>
                          {routePage === "marketplace" && (
                            <div className={styles.subMenuMobileLink}>
                              Marketplace
                            </div>
                          )}
                          {routePage === "dex" && (
                            <div className={styles.subMenuMobileLink}>Dex</div>
                          )}
                          {routePage === "staking" && (
                            <div className={styles.subMenuMobileLink}>
                              Staking
                            </div>
                          )}
                          <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown>
                  )}
                </div>
              </div>
            </Col>

            <Col xs={10} sm={10} md={4} lg={5}>
              <div>
                <Dropdown
                  overlay={<Menu />}
                  className={styles.dropdown}
                  placement="bottom"
                >
                  <Button
                    key={selected?.key}
                    icon={selected?.icon}
                    style={{ ...customStyles.button, ...customStyles.item }}
                  >
                    <span
                      className={styles.textChain}
                      style={{ marginLeft: "5px" }}
                    >
                      {selected?.value}
                    </span>
                  </Button>
                </Dropdown>
              </div>
            </Col>
          </div>
        </Row>

        <RoutingMenu
          isOpen={isOpen}
          setVisileSubMenu={setVisileSubMenu}
          visileSubMenu={visileSubMenu}
        />

        {!md && visileSubMenu && (
          <div className={styles.subMenuMobile}>
            <Link
              to="/"
              className={styles.subMenuMobileLink}
              onClick={handleClickSubMenu}
            >
              <div
                className={clsx(styles.logoLink, {
                  [styles.logoActive]: routePage === "marketplace",
                })}
              >
                <MarketplaceLogo
                  onClick={() => setVisileSubMenu(true)}
                  className="icon-logo"
                />
              </div>
              <span className={styles.textLink}>Marketplace</span>
            </Link>

            <Link
              to="/dex"
              className={styles.subMenuMobileLink}
              onClick={handleClickSubMenu}
            >
              <div
                className={clsx(styles.logoLink, {
                  [styles.logoActive]: routePage === "dex",
                })}
              >
                <DexLogo
                  onClick={() => setVisileSubMenu(true)}
                  className="icon-logo"
                />
              </div>
              <span className={styles.textLink}>DEX</span>
            </Link>
            <Link
              to="/staking"
              className={styles.subMenuMobileLink}
              onClick={handleClickSubMenu}
              ư
            >
              <div
                className={clsx(styles.logoLink, {
                  [styles.logoActive]: routePage === "staking",
                })}
              >
                <GameLogo
                  onClick={() => setVisileSubMenu(true)}
                  className="icon-logo"
                />
              </div>
              <span className={styles.textLink}>Staking</span>
            </Link>

            {/* <Link to="/marketplace">
              <Space
                onClick={handleClickSubMenu}
                className={clsx({
                  [styles.mainMenuActive]: routePage === "marketplace",
                })}
              >
                <img src={marketPlaceLogo} alt="marketplace logo" />
                <span>NFT Marketplace</span>
              </Space>
            </Link>

            <Link to="/dex">
              <Space
                onClick={handleClickSubMenu}
                className={clsx({
                  [styles.mainMenuActive]: routePage === "dex",
                })}
              >
                <img src={dexLogo} alt="dex logo" />
                <span>DEX</span>
              </Space>
            </Link>

            <Link to="/staking">
              <Space
                onClick={handleClickSubMenu}
                className={clsx({
                  [styles.mainMenuActive]: routePage === "staking",
                })}
              >
                <img src={gameLogo} alt="game logo" />
                <span>Game</span>
              </Space>
            </Link> */}
          </div>
        )}
      </Header>
    </>
  );
}

export default MainHeader;

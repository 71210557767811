import Icon from '@ant-design/icons';
import { ReactComponent as LogoHomeIcon } from './Logo.svg';
const LogoHome = (props) => {
  return <Icon component={LogoHomeIcon} {...props} />;
};

const UserIcon = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_30_4963)">
        <path
          d="M9.2149 0C9.61146 0.0887035 10.0237 0.140882 10.4098 0.26611C12.7056 1.00183 14.2345 3.26116 14.0518 5.60919C13.8536 8.10854 12.0586 10.0809 9.63233 10.4774C6.63729 10.9627 3.85096 8.8286 3.54311 5.80225C3.25091 2.93243 5.34326 0.33916 8.20786 0.036525C8.26004 0.0313071 8.307 0.0156536 8.35918 0C8.64094 0 8.92792 0 9.2149 0Z"
          fill="white"
        />
        <path
          d="M8.78695 20C6.09455 20 3.39692 20 0.704519 20C0.208825 20 -0.0103246 19.7756 0.000111072 19.2851C0.0470716 15.5805 2.78644 12.408 6.45979 11.8132C6.79374 11.761 7.1329 11.7349 7.47206 11.7297C8.31734 11.7193 9.16263 11.7193 10.0079 11.7245C13.41 11.7558 16.405 14.0725 17.2972 17.3598C17.4694 17.9963 17.5634 18.6434 17.5738 19.306C17.579 19.7756 17.3546 20.0052 16.8903 20.0052C14.1874 20 11.4898 20 8.78695 20Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_30_4963">
          <rect width="17.5737" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const WalletIcon = ({ ...props }) => {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_30_4945)">
        <path
          d="M22.3899 7.99733C22.2764 7.99733 22.1896 7.99733 22.0961 7.99733C20.8745 7.99733 19.6462 7.99065 18.4246 7.99733C16.1348 8.00401 14.3591 9.81976 14.3992 12.1028C14.4326 14.1989 16.1949 15.9613 18.2911 15.988C19.5594 16.0013 20.8211 15.9947 22.0895 15.9947C22.1829 15.9947 22.2697 15.9947 22.3765 15.9947C22.3832 16.0681 22.3965 16.1348 22.3965 16.1949C22.3965 16.7891 22.4032 17.3765 22.3965 17.9706C22.3832 19.1188 21.522 19.9866 20.3605 19.9933C18.3311 20 16.2951 19.9933 14.2657 19.9933C10.6275 19.9933 6.98932 19.9933 3.34446 19.9933C1.37517 19.9933 0 18.6182 0 16.6422C0 12.2163 0 7.78371 0 3.35114C0 1.38184 1.37517 0 3.34446 0C8.1976 0 13.0574 0 17.9105 0C18.6515 0 19.1989 0.514019 19.1923 1.20828C19.1923 1.83578 18.6983 2.35648 18.0708 2.39653C17.9706 2.4032 17.8705 2.4032 17.7704 2.4032C12.9706 2.4032 8.17757 2.4032 3.37784 2.4032C3.27103 2.4032 3.15754 2.39653 3.05073 2.41656C2.6502 2.48999 2.36315 2.86382 2.39653 3.26435C2.42323 3.65821 2.75033 3.97196 3.15087 3.99867C3.24433 4.00534 3.33111 3.99867 3.42457 3.99867C9.04539 3.99867 14.6662 3.99867 20.287 3.99867C21.4286 3.99867 22.3231 4.78638 22.3698 5.86115C22.4099 6.56208 22.3899 7.26302 22.3899 7.99733Z"
          fill="white"
        />
        <path
          d="M22.3832 9.6195C22.3832 11.2083 22.3832 12.7837 22.3832 14.3725C22.3231 14.3792 22.2697 14.3925 22.2163 14.3925C20.9346 14.3925 19.6529 14.3992 18.3712 14.3925C17.2697 14.3859 16.3485 13.6449 16.0748 12.5901C15.8077 11.5487 16.255 10.4473 17.2163 9.92657C17.5367 9.75301 17.9172 9.62617 18.2777 9.6195C19.6195 9.58612 20.9613 9.60615 22.3031 9.60615C22.3164 9.59947 22.3431 9.61282 22.3832 9.6195Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_30_4945">
          <rect width="22.3965" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const HambugerBar = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="20" height="2" rx="1" fill="white" />
      <rect y="14" width="20" height="2" rx="1" fill="white" />
      <rect y="7" width="20" height="2" rx="1" fill="white" />
    </svg>
  );
};

const IconArrowDown = () => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 5L6 10L1 5"
        stroke="var(--theme-color-bold)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const InstallApp = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" margin="auto" viewBox="0 0 24 24">
      <g clipPath="url(#clip0_30_4945)">
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path fill='white' d="M18 1.01L8 1c-1.1 0-2 .9-2 2v3c0 .55.45 1 1 1s1-.45 1-1V5h10v14H8v-1c0-.55-.45-1-1-1s-1 .45-1 1v3c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM11 15c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1s.45 1 1 1h2.59L3.7 14.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L10 11.41V14c0 .55.45 1 1 1z" />
      </g>

      <defs>
        <clipPath id="clip0_30_4945">
          <rect width="22.3965" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export { LogoHome, UserIcon, WalletIcon, HambugerBar, IconArrowDown, InstallApp };

import clsx from "clsx";
import React from "react";

import styles from "../../styles.module.css";
import { Button } from "antd";
import LayoutItemContent from "./DashboardLayout/LayoutItemContent";
import DashboardLayoutHeader from "./DashboardLayout/DashboardLayoutHeader";
import moment from "moment";
import { useState, useEffect } from "react";
import { useMoralis, useWeb3ExecuteFunction } from "react-moralis";
import axios from "axios";
import Constants from "constant";
import { ethers } from "ethers";
import ModalReferral from "./DashboardLayout/ModalReferral";
import apis from "cores/apis";
import IPFSGatewayTools from "@pinata/ipfs-gateway-tools/dist/browser";

const GameDashboardContent = ({ contract, setShow, show }) => {
  const serverURL = process.env.REACT_APP_MORALIS_SERVER_URL;
  const appId = process.env.REACT_APP_MORALIS_APPLICATION_ID;
  const gatewayTools = new IPFSGatewayTools();

  // const { account, isAuthenticated, authenticate } = useMoralis();
  const { account, isAuthenticated, authenticate } = useMoralis();

  const [isLoading, setIsLoading] = useState(false);
  const { Moralis } = useMoralis();

  Moralis.initialize(appId);
  Moralis.serverURL = serverURL;
  const [total, setTotal] = useState(0);
  const [NFTs, setNFTs] = useState([]);
  const { abiStaking, addrStaking, abiCollection, addrCollection } = contract;
  // const NFTaddr = Constants.contracts.NFT_COLLECTION_ADDRESS;
  const contractProcessor = useWeb3ExecuteFunction();
  const smStaking = new ethers.Contract(
    addrStaking,
    abiStaking,
    Constants.provider
  );
  const claim = async () => {
    setIsLoading(true);
    console.log("claim");
    const ops = {
      contractAddress: addrStaking,
      functionName: "claimRewards",
      abi: abiStaking,
      params: {},
    };
    await contractProcessor.fetch({
      params: ops,
      onSuccess: () => {
        setIsLoading(false);
        setTotal(0);
      },
      onError: (error) => {
        setIsLoading(false);
        console.error(error);
      },
    });
  };
  const getNFTs = async () => {
    const stakingContract = new ethers.Contract(
      addrStaking,
      abiStaking,
      Constants.provider
    );
    const collectionContract = new ethers.Contract(
      addrCollection,
      abiCollection,
      Constants.provider
    );
    const tokenIds = await stakingContract.stakedIds(account);
    for (let i = 0; i < tokenIds.length; i++) {
      const tokenId = tokenIds[i];
      let tokenURI = (await collectionContract.tokenURI(tokenId)).toString();
      console.log("tokenURI", tokenURI);
      if (gatewayTools.containsCID(tokenURI).containsCid) {
        tokenURI = gatewayTools.convertToDesiredGateway(tokenURI, Constants.GATEWAY);
      }
      const metadata = (await axios.get(tokenURI)).data;
      if (metadata) {
        let item = {};
        let linkImage = metadata.image.replace("ipfs://", "");
        let arrStr = linkImage.split("/");
        let nameImage, bucket;
        bucket = Constants.storageBucket.CHARGE_BUCKET;
        nameImage = arrStr[1];

        // get staked time of NFT
        const stakedTime = await stakingContract.timeStaked(tokenId);
        console.log("stakedTime", stakedTime.toString());
        item = {
          image: bucket + nameImage,
          description: metadata.description,
          tokenId: tokenId?.toString(),
          name: metadata.name,
          stakedTime: moment.unix(stakedTime?.toString()).toDate(),
        };
        console.log("item in staking", item);
        setNFTs((NFTs) => [...NFTs, item]);
      }
    }
  };

  const getTotal = async () => {
    const res = await smStaking.userStakeInfo(account);
    console.log("res", res?._availableRewards.toString());
    setTotal(ethers.utils.formatEther(res?._availableRewards.toString()));
  };
  // console.log(NFTs);
  useEffect(() => {
    if (isAuthenticated && account) {
      setNFTs([]);
      getNFTs();
    } else {
      setNFTs([]);
    }
  }, [account, isAuthenticated]);
  useEffect(() => {
    if (account && isAuthenticated) {
      getTotal();
    } else {
      setTotal(0);
    }
  }, [account, isAuthenticated]);

  return (
    <div className={clsx(styles.gameDashboard)}>
      <DashboardLayoutHeader
        contract={contract}
        setShow={setShow}
        show={show}
        extraCn={styles.gameDashboardHeaderMobile}
      />

      <div className={clsx(styles.gameDashboardTitle)}>Staking</div>

      <div className={clsx(styles.gameDashboardContent)}>
        {NFTs.map((nft, index) => (
          <LayoutItemContent
            key={index}
            item={{
              title: nft.name,
              description: nft.description,
              code: "#" + nft.tokenId,
              tokenId: nft.tokenId,
              stakedTime: nft.stakedTime,
            }}
            type="sr"
            image={nft.image}
            contract={contract}
          />
        ))}
      </div>

      <div
        className={clsx(styles.gameDashboardFooter, {
          [styles.show]: show,
        })}
      >
        <div>
          <p className={styles.gameDashboardFooterTitle}>
            Total {Constants.token.TOKEN_SYMBOL} Rewards
          </p>
          <div className={clsx("input-text", styles.inputCollectible)}>
            {total}
            {/* {Constants.token.TOKEN_SYMBOL} */}
          </div>
        </div>

        <div>
          <>
            <p className={styles.gameDashboardFooterTitle}>
              Daily NFTs Staking
            </p>
            <Button
              block
              disabled={total <= 0}
              loading={isLoading}
              onClick={() => claim()}
            >
              Claim
            </Button>
          </>
        </div>
      </div>
    </div>
  );
};

export default GameDashboardContent;

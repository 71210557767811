import { RightOutlined } from "@ant-design/icons";
import { Card, Col, Row, Typography, Tooltip, Spin, Button } from "antd";
import clsx from "clsx";
import { CopyIcon } from "components/Icons";
import styles from "../styles.module.css";
import "./Refferal.css";
import Tree from "react-d3-tree";
import { useMoralis } from "react-moralis";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BinaryTree from "cores/binaryTree";
import { useCenteredTree } from "./helpers";
import { isEmpty } from "lodash";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { failureModal, successModal } from "helpers/modals";
import { ethers } from "ethers";
import apis from "cores/apis";
import Constants from "constant";
const ReferralSystem = ({ toggleReferral }) => {
  const [data, setData] = useState({});
  const [commission, setCommission] = useState(0);
  const [rewards, setRewards] = useState(0);
  const { account, isAuthenticated, web3 } = useMoralis();
  const [isLoadingTree, setIsLoadingTree] = useState(false);
  const [isLoadingLink, setIsLoadingLink] = useState(false);
  const [binaryData, setBinaryData] = useState({});
  const [isRoot, setIsRoot] = useState(false);
  const [isLoadingCommission, setIsLoadingCommission] = useState(false);
  const [isLoadingReward, setIsLoadingReward] = useState(false);
  const [translate, containerRef] = useCenteredTree();
  const [isLoadingClaim, setIsLoadingClaim] = useState(false);
  const containerStyles = {
    width: "100vw",
    height: "100vh",
    marginTop: "-310px",
  };
  // check exist account
  useEffect(() => {
    if (isAuthenticated && account) {
      (async () => {
        setIsLoadingLink(true);
        try {
          const result = await apis.getAccount(account);
          if (result?.code === 200) {
            console.log("Account Data", result?.data);
            if (!isEmpty(result?.data)) {
              setData({
                ...result.data,
                linkLeft: `${window.location.origin}/invite/left/${result.data.code}`,
                linkRight: `${window.location.origin}/invite/right/${result.data.code}`,
              });
              setIsLoadingLink(false);
              setIsRoot(false);
              if (!isEmpty(result?.data) && result.data?.isCloned) {
                // get commission
                await getRewardAndCommission();
                // get binary tree
                await getBinaryTree({
                  contract: result.data?.contract,
                  contractOwner: result.data?.contractOwner,
                  isCloned: result.data?.isCloned,
                });
              }
            } else {
              setData({});
              setIsRoot(true);
              setIsLoadingLink(false);
            }
          } else {
            failureModal("Error", result?.data?.error);
          }
        } catch (err) {
          console.error(err);
          setIsLoadingLink(false);
        }
      })();
    }
  }, [account, isAuthenticated]);
  const getRewardAndCommission = async () => {
    setIsLoadingCommission(true);
    setIsLoadingReward(true);
    try {
      const result = await apis.getBonus(account);
      if (result.code === 200) {
        console.log("Bonus Data", result.data);
        setCommission(result?.data?.commission);
        setRewards(result?.data?.reward);
        setIsLoadingCommission(false);
        setIsLoadingReward(false);
      } else {
        failureModal("Error", result?.data?.error);
        setIsLoadingCommission(false);
        setIsLoadingReward(false);
      }
    } catch (err) {
      console.error(err);
      setIsLoadingCommission(false);
      setIsLoadingReward(false);
      failureModal("Error", "Something went wrong while getting bonus");
    }
  };
  const getBinaryTree = async ({ contract, contractOwner, isCloned }) => {
    setIsLoadingTree(true);
    try {
      const resData = await apis.getTreeArray(
        account,
        contract,
        contractOwner,
        isCloned
      );
      if (resData.code === 200) {
        const arrayData = resData.data?.slice(1);
        const binaryTree = new BinaryTree(arrayData);
        const root = await binaryTree.insertLevelOrder(0);
        if (!isEmpty(root)) {
          const binaryDataSet = root.parseData();
          setBinaryData(binaryDataSet);
        }
      } else {
        failureModal("Error", resData?.data?.error);
      }
      setIsLoadingTree(false);
    } catch (err) {
      console.error("Error", err);
      failureModal("Error", "Something went wrong when getting tree");
      setIsLoadingTree(false);
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };
  const claim = async () => {
    console.group("===== Claim =====");
    setIsLoadingClaim(true);
    try {
      if (rewards <= 0) {
        failureModal("Error", "You don't have any rewards to claim");
        setIsLoadingClaim(false);
        return;
      }
      const rewardInWei = ethers.utils
        .parseEther(rewards.toString())
        ?.toString();
      const { TREASURY_ADDRESS } = Constants.contracts;
      const { address } = Constants.TARGET_TOKEN;
      const tokenAddress = address;
      const chainId = 56;
      const treasuryAddress = TREASURY_ADDRESS.toLowerCase();
      const treasuryABI = [
        "function nonces(address) external view returns (uint256)",
        "function withdraw(address, address,uint256,uint256,bytes) external",
      ];
      const userSigner = new ethers.providers.Web3Provider(
        window.ethereum
      ).getSigner();
      const provider = userSigner.provider;
      const signer = new ethers.Wallet(
        process.env.REACT_APP_SIGNER_PRIVATE_KEY,
        provider
      );
      const signerAddress = (await signer.getAddress())?.toString();
      const userAddress = (await userSigner.getAddress())?.toString();
      console.log("userSigner address", userAddress);
      console.log("houseSigner address", signerAddress);
      console.log("window.ethereum", window.ethereum);
      const treasuryContract = new ethers.Contract(
        treasuryAddress,
        treasuryABI,
        userSigner
      );
      const nonce = (await treasuryContract.nonces(userAddress)).toString();
      console.log("nonce", nonce);
      const block = await Constants.provider.getBlock("latest");
      const timestamp = block.timestamp;
      const deadline = (timestamp + 900).toString();
      const domain = {
        name: "Treasury",
        version: "2",
        chainId: chainId,
        verifyingContract: treasuryAddress,
      };
      console.log("domain", domain);
      const message = {
        token: tokenAddress,
        to: userAddress,
        amount: rewardInWei,
        deadline: deadline,
        nonce: nonce,
      };
      console.log("message", message);
      const types = {
        Permit: [
          { name: "token", type: "address" },
          { name: "to", type: "address" },
          { name: "amount", type: "uint256" },
          { name: "deadline", type: "uint256" },
          { name: "nonce", type: "uint256" },
        ],
      };
      const typeData = {
        types,
        primaryType: "Permit",
        domain: domain,
        message: message,
      };
      const signature = await signer._signTypedData(
        typeData.domain,
        typeData.types,
        typeData.message
      );
      console.log("Withdrawal input", {
        tokenAddress,
        userAddress,
        rewardInWei,
        deadline,
        signature,
      });
      setRewards(0);
      const tx = await treasuryContract.withdraw(
        tokenAddress,
        userAddress,
        rewardInWei,
        deadline,
        signature
      );
      console.log("Withdrawal tx", tx);
      const txConfirmation = await tx.wait();
      console.log("Tx Confirmation", txConfirmation);
      // update reward claimed
      const result = await apis.updateRewardClaimed(account, rewards);
      console.log("Update reward claimed", result);
      setIsLoadingClaim(false);
      successModal("Success", "Claimed successfully");
      console.groupEnd();
    } catch (err) {
      const error = { err };
      console.log("Place Bet Error", error);
      const reason =
        error?.err?.reason?.toString() ?? error?.err?.message?.toString();
      if (reason?.length !== 0) {
        if (reason === "execution reverted: TREASURY: EXPIRED") {
          failureModal("Error", "Nonce expired");
        } else if (
          reason === "execution reverted: TREASURY: INVALID_SIGNATURE"
        ) {
          failureModal("Error", `Invalid signature for ${account}`);
        } else if (
          reason === "execution reverted: TRANSFERABLE: TRANSFER_FAILED"
        ) {
          failureModal("Error", `Contract is not enough balance to transfer`);
        } else {
          failureModal("Error", reason);
        }
      }
      setIsLoadingClaim(false);
      console.groupEnd();
    }
  };
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Card className={styles.card}>
          <Link to="/profile">
            <RightOutlined className={styles.btnBack} />
          </Link>
          <Row gutter={[16, 16]}>
            <div className={styles.referalHeader} style={{ padding: "0 8px" }}>
              <div></div>

              <div
                className={styles.referralTitle}
                style={!isRoot ? { paddingLeft: "0px" } : null}
              >
                Referral System
              </div>
            </div>

            <Col span={24}>
              <Spin spinning={isLoadingLink}>
                <div className={clsx(styles.addressBox, styles.box)}>
                  <Typography.Text
                    strong
                    style={{
                      maxWidth: "100%",
                    }}
                    ellipsis={{
                      tooltip: data?.linkLeft,
                    }}
                  >
                    {data?.linkLeft}
                  </Typography.Text>
                  <Tooltip title="Copied" trigger="click" placement="top">
                    <span className={styles.iconCopy}>
                      <CopyIcon
                        onClick={() => handleCopy(data?.linkLeft)}
                        style={{ color: "#fff", fontSize: 12 }}
                      />
                    </span>
                  </Tooltip>
                </div>
              </Spin>
            </Col>
            <Col span={24}>
              <Spin spinning={isLoadingLink}>
                <div className={clsx(styles.addressBox, styles.box)}>
                  <Typography.Text
                    strong
                    style={{
                      maxWidth: "100%",
                    }}
                    ellipsis={{
                      tooltip: data?.linkRight,
                    }}
                  >
                    {data?.linkRight}
                  </Typography.Text>
                  <Tooltip title="Copied" trigger="click" placement="top">
                    <span className={styles.iconCopy}>
                      <CopyIcon
                        onClick={() => handleCopy(data?.linkRight)}
                        style={{ color: "#fff", fontSize: 12 }}
                      />
                    </span>
                  </Tooltip>
                </div>
              </Spin>
            </Col>
            <Col span={24}>
              <Spin spinning={isLoadingCommission}>
                <div className={clsx(styles.infoTotalBox, styles.box)}>
                  <Row gutter={4} style={{ width: "100%" }}>
                    <Col span={12}>
                      <span>Commission: </span>
                    </Col>
                    <Col span={12}>
                      <span>{commission} BUSD</span>
                    </Col>
                  </Row>
                </div>
              </Spin>
            </Col>
            <Col span={24}>
              {/* <div className={clsx(styles.infoCommissionlBox, styles.box)}> */}
              <div style={{ display: "flex" }}>
                <Spin spinning={isLoadingReward}>
                  <div className={clsx(styles.infoCommissionlBox, styles.box)}>
                    <Col flex={3}>
                      <span>Your rewards:</span>
                    </Col>
                    <Col flex={2}>
                      <span>{rewards} BUSD</span>
                    </Col>
                  </div>
                </Spin>

                <Col span={6} style={{ paddingRight: "0px" }}>
                  <Button
                    loading={isLoadingClaim}
                    disabled={rewards === 0}
                    style={{
                      background: "#fff",
                      borderRadius: 8,
                      border: "2px solid #8578ff",
                      width: "100%",
                      height: "100%",
                      color: "#8578ff",
                      fontWeight: "bold",
                    }}
                    className={styles["claim-btn"]}
                    onClick={() => claim()}
                  >
                    Claim
                  </Button>
                </Col>
              </div>
            </Col>
            {/* {isLoading ? (
              <LoadingIndicator />
            ) : (
              <Col span={24}>
                <div className={styles.tree}>
                  <Tree
                    showLine={{ showLeafIcon: false }}
                    defaultExpandAll={true}
                    switcherIcon={null}
                    className={styles.referralNode}
                  >
                    {children && renderNode(children)}
                  </Tree>
                </div>
              </Col>
            )} */}
            {isLoadingTree ? (
              <LoadingIndicator />
            ) : (
              !isEmpty(binaryData) &&
              data?.isCloned && (
                <div
                  id="treeWrapper"
                  style={containerStyles}
                  ref={containerRef}
                >
                  <Tree
                    rootNodeClassName="node__root"
                    branchNodeClassName="node__branch"
                    leafNodeClassName="node__leaf"
                    translate={translate}
                    // renderCustomNodeElement={renderRectSvgNode}
                    // renderCustomNodeElement={renderRectSvgNode}
                    data={binaryData}
                    orientation="vertical"
                    pathFunc="straight"
                    nodeSize={{ x: 100, y: 100 }}
                  />
                </div>
              )
            )}
          </Row>
        </Card>
      </div>
    </>
  );
};

export default ReferralSystem;

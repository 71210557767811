import React from 'react';

const StackSvg = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 4.34038C15.9326 4.54257 15.7843 4.6504 15.6091 4.73128C13.1154 5.97138 10.6352 7.21148 8.15501 8.46506C8.04718 8.51898 7.9663 8.51898 7.87195 8.46506C5.35131 7.21148 2.85762 5.9579 0.350463 4.7178C0.148273 4.60996 0 4.47517 0 4.23254C0 3.98991 0.148273 3.85512 0.350463 3.76076C2.80371 2.53414 5.25695 1.30752 7.71019 0.0808985C7.8989 -0.0134571 8.08762 -0.0134571 8.27633 0.0808985C10.743 1.30752 13.2098 2.53414 15.6765 3.76076C15.8382 3.84164 15.9326 3.96296 16 4.12471C16 4.1921 16 4.27298 16 4.34038Z"
        fill="currentColor"
      />
      <path
        d="M16 7.99326C15.9191 8.18197 15.7709 8.28981 15.5821 8.38416C13.1019 9.62427 10.6217 10.8644 8.15501 12.1045C8.04718 12.1584 7.9663 12.1584 7.87195 12.1045C5.35131 10.8509 2.84414 9.59731 0.336984 8.34373C0.134794 8.24937 0 8.1011 0 7.87195C0 7.6428 0.134794 7.508 0.336984 7.41365C0.822241 7.17102 1.3075 6.94187 1.79275 6.68576C1.90059 6.63185 1.98147 6.63185 2.07582 6.68576C3.94945 7.62932 5.8096 8.5594 7.68323 9.48947C7.8989 9.59731 8.1011 9.59731 8.31676 9.48947C10.1769 8.5594 12.0505 7.62932 13.9107 6.68576C14.032 6.63185 14.1129 6.63185 14.2207 6.68576C14.679 6.91491 15.1238 7.14406 15.5821 7.37321C15.7574 7.45409 15.9056 7.5754 15.9865 7.75063C16 7.83151 16 7.91239 16 7.99326Z"
        fill="currentColor"
      />
      <path
        d="M16.0001 11.6192C15.9327 11.8214 15.7844 11.9293 15.6092 12.0101C13.1964 13.2098 10.7701 14.4229 8.35727 15.6361C8.11464 15.7574 7.91245 15.7574 7.66982 15.6361C5.23006 14.4229 2.79029 13.2098 0.350527 11.9832C0.0539809 11.8349 -0.0673333 11.6057 0.0405016 11.3631C0.0944191 11.2418 0.188775 11.1205 0.296609 11.0666C0.808825 10.797 1.33452 10.5409 1.86022 10.2848C1.91413 10.2578 2.00849 10.2713 2.07589 10.2983C3.88212 11.2014 5.68835 12.091 7.48111 13.0076C7.85853 13.1963 8.15508 13.1963 8.54598 13.0076C10.3387 12.091 12.145 11.2014 13.9377 10.2983C14.0456 10.2443 14.113 10.2443 14.2208 10.2983C14.6791 10.5409 15.1509 10.77 15.6092 10.9992C15.7844 11.0935 15.9327 11.2014 16.0001 11.3901C16.0001 11.471 16.0001 11.5518 16.0001 11.6192Z"
        fill="currentColor"
      />
      <defs>
        <linearGradient
          id="paint0_linear_52_2975"
          x1="8"
          y1="0.0101318"
          x2="8"
          y2="8.5055"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--theme-color)" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_52_2975"
          x1="8"
          y1="6.64532"
          x2="8"
          y2="12.1449"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--theme-color)" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_52_2975"
          x1="8.00089"
          y1="10.2578"
          x2="8.00089"
          y2="15.7271"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--theme-color)" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default StackSvg;

import { SearchOutlined } from "@ant-design/icons";
import { Button, Grid, Input, Menu } from "antd";
import clsx from "clsx";
import Account from "components/Account/Account";
import DexLogo from "components/Icons/DexLogo";
import GameLogo from "components/Icons/GameLogo";
import MarketplaceLogo from "components/Icons/MarketplaceLogo";
import { NavLink } from "react-router-dom";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { IconArrowDown } from "./IconHeader";
import styles from "./styles.module.css";
import { UserIcon } from "../MainHeader/IconHeader";
import Constants from "constant";
import SiteMapIcon from "components/Icons/SiteMapIcon";
import { useState } from "react";
import ReferralSystem from "components/Profile/components/ReferralSystem";

const { useBreakpoint } = Grid;
const stakingPaths = ["/staking/free", "/staking/charge"];

const dexPaths = [
  "/dex",
  "/dex/transfers",
  "/dex/balances",
  "/dex/transactions",
];

const RoutingMenu = ({ isOpen, visileSubMenu, setVisileSubMenu }) => {
  const { pathname } = useLocation();

  const history = useHistory();
  // const dexMatchedPath = matchPath(pathname, { path: "/dex/:type" });

  const [isOpenReferral, setIsOpenReferral] = useState(false);

  const isStakingPath = stakingPaths.includes(pathname);
  const isDexPath = dexPaths.includes(pathname);

  const selectedKey = pathname.split("/")[1];
  const selectedKeydex = pathname.split("/dex/")[1];
  const selectedKeyStaking = pathname.split("/staking/")[1];
  const { sm, md } = useBreakpoint();

  let deferredInstall = null;

  const renderStakingMenu = () => {
    return (
      !visileSubMenu && (
        <div className={styles.bottomBg}>
          <div
            className={isOpen ? styles.bottomHeader : styles.bottomHeaderClose}
          >
            {!md ? (
              <div className={styles.logoHomeWrapper}>
                <div className={styles.logoMobile}>
                  <GameLogo
                    onClick={() => setVisileSubMenu(true)}
                    className="icon-logo"
                  />
                </div>{" "}
                <IconArrowDown />
              </div>
            ) : (
              <div className={styles.logoHomeWrapper}>
                <GameLogo
                  onClick={() => history.push("/")}
                  className="icon-logo"
                />
              </div>
            )}

            <div className={styles.menuRight}>
              <div className={styles.menuItems}>
                <Menu
                  theme="dark"
                  mode="horizontal"
                  className={`${styles.bottomMenu} ${!sm ? styles.bottomMenuSm : ""
                    }`}
                  selectedKeys={
                    selectedKeyStaking ? selectedKeyStaking : "free"
                  }
                >
                  <Menu.Item key={Constants.collections.types.CHARGE}>
                    <Link
                      to={`/staking/${Constants.collections.types.CHARGE}`}
                      className={styles.menuLink}
                    >
                      AI Robots
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key={Constants.collections.types.FREE}>
                    <Link
                      to={`/staking/${Constants.collections.types.FREE}`}
                      className={styles.menuLink}
                    >
                      Free Collection
                    </Link>
                  </Menu.Item> */}
                </Menu>
                <div className={styles.accountWrapper}>
                  <div
                    className={styles.walletInfo}
                    style={{ marginRight: "10px" }}
                  >
                    <div
                      onClick={() => history.push("/profile")}
                      className={styles.icon1}
                    >
                      <UserIcon className={styles.svgIcon1} />
                    </div>
                  </div>
                  <div style={{ marginRight: "10px" }}>
                    <Link
                      // onClick={setIsOpenReferral}
                      to="/referral"
                      // type="primary"
                      style={{
                        marginTop: "-4px",
                        background: "transparent",
                        // borderRadius: 8,
                        border: "none",
                      }}
                    >
                      <div className={styles.icon1}>
                        <SiteMapIcon
                          style={{
                            color: "#fff",
                            fontSize: "25px",
                            marginLeft: "8px",
                          }}
                        />
                      </div>
                    </Link>
                  </div>
                  <div className={styles.walletInfo}>
                    <Account />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };
  const renderDexMenu = () => {
    return (
      !visileSubMenu && (
        <div className={styles.bottomBg}>
          <div
            className={isOpen ? styles.bottomHeader : styles.bottomHeaderClose}
          >
            {!md ? (
              <div className={styles.logoHomeWrapper}>
                <div className={styles.logoMobile}>
                  <DexLogo
                    onClick={() => setVisileSubMenu(true)}
                    className="icon-logo"
                  />
                </div>
                <IconArrowDown />
              </div>
            ) : (
              <div className={styles.logoHomeWrapper}>
                <DexLogo
                  onClick={() => history.push("/dex")}
                  className="icon-logo"
                />
              </div>
            )}

            <div className={styles.menuRight}>
              <div className={styles.menuItems}>
                <Menu
                  theme="dark"
                  mode="horizontal"
                  // defaultSelectedKeys={['explore']}
                  className={`${styles.bottomMenu} ${!sm ? styles.bottomMenuSm : ""
                    }`}
                  selectedKeys={selectedKeydex ? selectedKeydex : "dex"}
                >
                  <Menu.Item key="dex">
                    <Link to="/dex" className={styles.menuLink}>
                      DEX
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="transfers">
                    <Link to="/dex/transfers" className={styles.menuLink}>
                      Transfer
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="balances">
                    <Link to="/dex/balances" className={styles.menuLink}>
                      Balances
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="transactions">
                    <Link to="/dex/transactions" className={styles.menuLink}>
                      Transactions
                    </Link>
                  </Menu.Item>
                </Menu>
                <div className={styles.accountWrapper}>
                  <div
                    className={styles.walletInfo}
                    style={{ marginRight: "10px" }}
                  >
                    <div
                      onClick={() => history.push("/profile")}
                      className={styles.icon1}
                    >
                      <UserIcon className={styles.svgIcon1} />
                    </div>
                  </div>
                  <div style={{ marginRight: "10px" }}>
                    <Link
                      // onClick={setIsOpenReferral}
                      to="/referral"
                      // type="primary"
                      style={{
                        marginTop: "-4px",
                        background: "transparent",
                        // borderRadius: 8,
                        border: "none",
                      }}
                    >
                      <div className={styles.icon1}>
                        <SiteMapIcon
                          style={{
                            color: "#fff",
                            fontSize: "25px",
                            marginLeft: "8px",
                          }}
                        />
                      </div>
                    </Link>
                  </div>
                  <div className={styles.walletInfo}>
                    <Account />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const toggleReferral = () => setIsOpenReferral((v) => !v);

  if (isStakingPath) {
    return renderStakingMenu();
  }

  if (isDexPath) {
    return renderDexMenu();
  }

  //listen for `appinstalled` event
  window.addEventListener("appinstalled", (evt) => {
    //deprecated but still runs in Chrome-based browsers.
    //Not very useful event.
    //Better to use the DOMContentLoaded and then look at how it was launched
  });

  //listen for `beforeinstallprompt` event
  window.addEventListener("beforeinstallprompt", (ev) => {
    // Prevent the mini-infobar from appearing on mobile
    ev.preventDefault();
    // Stash the event so it can be triggered later.
    deferredInstall = ev;
    console.log("saved the install event");
    // Update UI notify the user they can install the PWA
    // if you want here...
  });

  function startChromeInstall() {
    if (deferredInstall) {
      console.log(deferredInstall);
      deferredInstall.prompt();
      deferredInstall.userChoice.then((choice) => {
        if (choice.outcome === "accepted") {
          //they installed
          // console.log("installed");
        } else {
          // console.log("cancel");
        }
      });
    }
  }

  // Render Marketplace Menu
  return (
    !visileSubMenu && (
      <div className={styles.bottomBg}>
        <div
          className={isOpen ? styles.bottomHeader : styles.bottomHeaderClose}
        >
          {!md ? (
            <div className={styles.logoHomeWrapper}>
              <div className={styles.logoMobile}>
                <MarketplaceLogo
                  onClick={() => setVisileSubMenu(true)}
                  className="icon-logo"
                />
              </div>
              <IconArrowDown />
            </div>
          ) : (
            <div className={styles.logoHomeWrapper}>
              <MarketplaceLogo
                onClick={() => history.push("/")}
                className="icon-logo"
              />
            </div>
          )}
          <div className={styles.menuRight}>
            {!md && (
              <Input
                className={styles.input}
                size="large"
                placeholder="Enter your search here"
                prefix={
                  <SearchOutlined style={{ fontSize: "20px", color: "#fff" }} />
                }
              />
            )}
            {/* <div className={styles.walletInfo} style={{ marginLeft: "10px" }}>
              <div
                onClick={() => history.push("/profile")}
                className={styles.icon1}
              ></div>
            </div> */}

            <div className={styles.menuItems}>
              <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={["/"]}
                className={`${styles.bottomMenu} ${!sm ? styles.bottomMenuSm : ""
                  }`}
                selectedKeys={selectedKey}
              >
                <Menu.Item key="explore">
                  <NavLink to="/explore" className={styles.menuLink}>
                    Explore
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="my-collection">
                  <NavLink to="/my-collection" className={styles.menuLink}>
                    My Collections
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="transaction">
                  <NavLink to="/transaction" className={styles.menuLink}>
                    Transactions
                  </NavLink>
                </Menu.Item>
                <Menu.Item className={styles.btnCreate}>
                  <NavLink to="/create-nft" className={clsx(styles.menuLink)}>
                    Create
                  </NavLink>
                </Menu.Item>
              </Menu>
              {md && (
                <Input
                  className={`${styles.input} ${styles.inputDesktop}`}
                  size="large"
                  placeholder="Enter your search here"
                  prefix={
                    <SearchOutlined
                      style={{ fontSize: "20px", color: "#fff" }}
                    />
                  }
                />
              )}
              {/* <div className={styles.walletInfo} style={{ marginLeft: "10px" }}>
                <div
                  onClick={() => startChromeInstall()}
                  className={styles.icon1}
                >
                  <InstallApp className={styles.svgIcon1}/>
                </div>
              </div> */}
              <div className={styles.accountWrapper}>
                <div
                  className={styles.walletInfo}
                  style={{ marginRight: "10px" }}
                >
                  <div
                    onClick={() => history.push("/profile")}
                    className={styles.icon1}
                  >
                    <UserIcon className={styles.svgIcon1} />
                  </div>
                </div>
                <div style={{ marginRight: "10px" }}>
                  <Link
                    // onClick={setIsOpenReferral}
                    to="/referral"
                    // type="primary"
                    style={{
                      marginTop: "-4px",
                      background: "transparent",
                      // borderRadius: 8,
                      border: "none",
                    }}
                  >
                    <div className={styles.icon1}>
                      <SiteMapIcon
                        style={{
                          color: "#fff",
                          fontSize: "25px",
                          marginLeft: "8px",
                        }}
                      />
                    </div>
                  </Link>
                </div>
                {!md && (
                  <div className={styles.walletInfo}>
                    <Account />
                  </div>
                )}
                {md && (
                  <div className={styles.walletInfo}>
                    <Account />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default RoutingMenu;
